import React from 'react';
import Code from './code';
import {NavLink} from 'react-router-dom';
import BackArrow from './backarrow';

const Project = (props)=>{
	console.log('PROJECT COMPONENt');
	console.log(props);
	let headerContainerObj = {};
	return <div className={'singleProject' } id={'singleProject' + props.index}  key={'singleProject' + props.index} onClick={(e)=>{props.onClick(e);}}>
		{Object.keys(props.info).map(projectElement => {
			if (projectElement === 'title'){
				headerContainerObj['title'] = props.info[projectElement];
			} else if (projectElement === 'subTitle'){
				headerContainerObj['subTitle'] = props.info[projectElement];
			} else if(projectElement === 'link'){
				headerContainerObj['link'] = props.info[projectElement];
			}	else if (projectElement === 'technicalSkills'){
				return <TechnicalSkills key={props.index + 'technicalSkills'} skills = { props.info[projectElement]} resetLayout={props.resetLayout} index={props.index}/>;
			} else if (projectElement === 'description'){
				return <Description key={props.index + 'description'} description={props.info[projectElement]} link={headerContainerObj['link']}/>;
			}

			if(Object.keys(headerContainerObj).length === 3){
				let heading = Object.assign({}, headerContainerObj);
				//console.log(`headerContainerObj length is 2 :  projectElement ${projectElement}  i ${i}`);
				delete headerContainerObj['title'];
				return <ProjectHeadingContainer heading={heading} key={props.index + 'header'}/>;
			}

		})}
	</div>;
};


const ProjectHeadingContainer= (props) =>{
	return <div className='projectHeader'>
		<div className='codeSVG'>
			<NavLink to={props.heading.link}>
				<Code/>
			</NavLink>
		</div>
		<div className='ProjectHeadingContainer' >

			<div className='projectHeadings'>
				<Title> {props.heading.title} </Title>
				<SubTitle> {props.heading.subTitle} </SubTitle>
			</div>
		</div>

	</div>
}

const Title = (props) => <h1> {props.children} </h1>;
const SubTitle = (props) => <h2> {props.children} </h2>;


const TechnicalSkills = (props) =>{
	return <div className='projectTechnicalSkillsContainer' >
		<div className='headerAndBackButton'>
			<h2> Technical Skills </h2>
			<button id={'backButton' + props.index } onClick={(e)=>{props.resetLayout(e);}}>
				<BackArrow />
			</button>
		</div>
		<div className='skillContainer'>
			{(props.skills).map((skill,index) =>{
				return <h3 key={'skill' + index}> {skill} </h3>;
			})}
		</div>
	</div>;
};

const Description = (props) => {
	console.log('DESCRIPTION');
	console.log(props);
	return <div className='projectDescription'>
		<h2> Description </h2>
		<h3> {props.description}</h3>
	</div>;
};


export default Project;
