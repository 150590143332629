import React from 'react';

let ContainerStyle = {
	fill:'white',
	stroke:'white',
	strokeWidth:'1.32291663'
};

let mainHeaderStyle={
	fontFamily:'Roboto',
	letterSpacing:'0.079375px',
	stroke:'none',
	strokeWidth:'0.26458332',
	fill:'#C9143B',
	fontSize:'7.76111126px'
};
let subHeaderStyle ={
	fontFamily:'Roboto',
	fontWeight:'100',
	fontSize:'4.93888855px',
	fill:'#001A2E',
	stroke:'none',
	strokeWidth:'0.26458332'
};
class Practice extends React.Component {
	constructor(props){
		super(props);
	}

	render() {
		let cursiveStyle = '\'Shadows Into Light Two\', cursive';
		let mainCursiveStyle = '\'Londrina Solid\', cursive';
		return (

			<svg

				viewBox="0 0 147.28299 153.45834"
				version="1.1"
				id="svg8">

			  <g id="layer1"
			     transform="translate(-82.182766,-109.33939)">
			    <path
			       style={(this.props.fun === false)?ContainerStyle : Object.assign({}, ContainerStyle, {fill:'#001A2E',stroke:'black'})}
			       d="m 82.880911,188.7082 c 1.096245,32.07168 21.397319,59.97668 50.742639,69.7487 l -1.77463,-24.04501 13.55944,-19.93628 c -10.46057,-4.14608 -17.72712,-14.15385 -18.70947,-25.76743 l -21.90898,-7.93748 z"
			       id="CSS3Container"/>
			      <g
			       id="g1080">
			      <text
			         id="CSS3"
			         y="210.65775"
			         x="102.12916"
			         style={(this.props.fun === false)?mainHeaderStyle : Object.assign({}, mainHeaderStyle, {fill:'white',fontFamily:mainCursiveStyle})}
			         ><tspan
			           id="tspan973"
			           y="210.65775"
			           x="102.12916"
			            ><tspan
			             id="tspan971"
			             style={(this.props.fun === false)?mainHeaderStyle : Object.assign({}, mainHeaderStyle, {fill:'white',fontFamily:mainCursiveStyle})}
			             y="210.65775"
			             x="102.12916">CSS3</tspan></tspan></text>
			      <text
			         id="text969"
			         y="216.2135"
			         x="103.71665"
			         style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
			         ><tspan
			           id="FlexBox"
			           y="216.2135"
			           x="103.71665"
			            ><tspan
			             id="tspan965"
			             style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
			             y="217.2135"
			             x="103.71665">Flexbox</tspan></tspan></text>
			      <text
			         id="text963"
			         y="220.94389"
			         x="102.22272"
			         style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
			         ><tspan

			           id="CSSGrid"
			           y="220.94389"
			           x="102.22272"
			            ><tspan
			             id="tspan959"
			             style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
			             y="222.94389"
			             x="102.22272">CSS Grid</tspan></tspan></text>
			    </g>
					<g
						id="g1017">
					 <path

							id="path4610"
							d="m 138.36486,112.29011 13.5553,19.92129 -1.77877,24.03004 c 0.46404,-0.0975 0.93009,-0.18316 1.39773,-0.25693 0.46762,-0.0738 0.93685,-0.13576 1.40723,-0.18582 0.94076,-0.10012 1.88619,-0.15263 2.83295,-0.15722 3.43872,2.5e-4 6.82225,0.63245 10.00407,1.84095 3.18182,1.20848 6.16192,2.99326 8.79374,5.29867 l 21.44374,-10.21842 10.21841,-21.44373 c -13.58269,-13.54385 -31.65721,-21.10825 -50.45996,-21.11809 -5.87207,0.021 -11.7191,0.79961 -17.41444,2.28926 z"
							style={(this.props.fun === false)?ContainerStyle : Object.assign({}, ContainerStyle, {fill:'#C9143B',stroke:'black'})} />
					 <g
							id="g1007">
						 <text

								style={(this.props.fun === false)?mainHeaderStyle : Object.assign({}, mainHeaderStyle, {fill:'white',fontFamily:mainCursiveStyle})}
								x="158.48541"
								y="134.45773"
								id="text933"><tspan
									x="158.48541"
									y="134.45773"
									id="tspan931"
									   ><tspan
										x="158.48541"
										y="134.45773"
										style={(this.props.fun === false)?mainHeaderStyle : Object.assign({}, mainHeaderStyle, {fill:'white',fontFamily:mainCursiveStyle})}
										id="tspan929">Javascript</tspan></tspan></text>
						 <text

								style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
								x="165.62917"
								y="140.57477"
								id="text927"><tspan

									x="165.62917"
									y="140.57477"
									id="tspan925"
									   ><tspan
										x="165.62917"
										y="141.50293"
										style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
										id="tspan923">ES6</tspan></tspan></text>
					 </g>
				 </g>
					<text

						style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
						x="177.09744"
						y="140.50293"
						id="text921"><tspan

							x="177.09744"
							y="140.50293"
							id="tspan919"
							   ><tspan
								x="177.09744"
								y="141.50293"
								style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
								id="tspan917">ES7</tspan></tspan></text>
				 <g
						id="g1069">
					 <path

							id="path875"
							d="m 178.20739,166.61755 c 4.3729,5.45913 6.77245,12.34108 6.78202,19.45102 -0.002,2.07293 -0.20683,4.13476 -0.60899,6.154 -0.40216,2.01923 -1.00138,3.99589 -1.7914,5.89848 l 15.22775,17.95713 23.16527,4.20896 c 5.13812,-10.61292 7.81717,-22.33243 7.82226,-34.21857 -0.0214,-18.88278 -6.8039,-37.07841 -19.00039,-51.04806 l -10.1856,21.41118 z"
							style={(this.props.fun === false)?ContainerStyle : Object.assign({}, ContainerStyle, {fill:'#FF9800',stroke:'black'})} />
					 <g
							id="g1059">
						 <text

								style={(this.props.fun === false)?mainHeaderStyle : Object.assign({}, mainHeaderStyle, {fill:'#001A2E',fontFamily:mainCursiveStyle})}
								x="194.81082"
								y="181.82043"
								id="text945"><tspan

									x="194.81082"
									y="181.82043"
									id="tspan943"
									   ><tspan
										x="194.81082"
										y="181.82043"
										style={(this.props.fun === false)?mainHeaderStyle : Object.assign({}, mainHeaderStyle, {fill:'#001A2E',fontFamily:mainCursiveStyle})}
										id="tspan941">HTML5</tspan></tspan></text>
						 <text

								style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'#001A2E'})}
								x="186.52727"
								y="187.29295"
								id="text939"><tspan

									x="186.52727"
									y="187.29295"
									id="tspan937"
									   ><tspan
										x="186.52727"
										y="188.29295"
										style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'#001A2E',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
										id="tspan935">Responsive Layout</tspan></tspan></text>
					 </g>
				 </g>
				 <g
						id="g999">
					 <path

							id="path4614"
							d="m 82.844221,183.42893 21.925779,-7.9375 21.9258,7.9375 c 0.96102,-11.4953 8.08163,-21.43526 18.38699,-25.66716 l 1.76999,-24.06312 -13.56409,-19.95437 c -29.20713,9.87066 -49.366551,37.71915 -50.444469,69.68465 z"
							style={(this.props.fun === false)?ContainerStyle : Object.assign({}, ContainerStyle, {fill:'#FF9800',stroke:'black'})} />
					 <g
							id="g986">
						 <text

								style={(this.props.fun === false)?mainHeaderStyle : Object.assign({}, mainHeaderStyle, {fill:'#001A2E',fontFamily:mainCursiveStyle})}
								x="96.828087"
								y="150.32172"
								id="text903"><tspan

									x="96.828087"
									y="150.32172"
									id="tspan901"
									   ><tspan
										x="106.828087"
										y="150.32172"
										style={(this.props.fun === false)?mainHeaderStyle : Object.assign({}, mainHeaderStyle, {fill:'#001A2E',fontFamily:mainCursiveStyle})}
										id="tspan899">React</tspan></tspan></text>
						 <text

								style={subHeaderStyle}
								x="103.74222"
								y="156.45068"
								id="text915"><tspan

									x="103.74222"
									y="156.45068"
									id="tspan913"
									   ><tspan
										x="103.74222"
										y="156.45068"
										style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'#001A2E',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
										id="tspan911">Redux Thunk</tspan></tspan></text>
						 <text

								style={subHeaderStyle}
								x="96.377808"
								y="161.40356"
								id="text909"><tspan

									x="96.377808"
									y="161.40356"
									id="tspan907"
									   ><tspan
										x="87.877808"
										y="162.40356"
										style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'#001A2E',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
										id="tspan905">Apollo Client, GraphQL</tspan></tspan></text>
					 </g>
				 </g>
				 <g
						id="g1044">
					 <path

							id="path4608"
							d="m 138.69301,259.93692 c 5.59224,1.43234 11.32752,2.17059 17.08628,2.19936 25.72741,-0.025 49.53898,-14.16952 62.68197,-37.20809 l -23.12573,-4.18622 -15.18821,-17.93438 c -5.39928,8.53729 -14.55359,13.67946 -24.36802,13.68805 -0.88998,-0.004 -1.77886,-0.0497 -2.66389,-0.13779 -0.88502,-0.0881 -1.76617,-0.21833 -2.6407,-0.39035 l -13.5579,19.93034 z"
							style={(this.props.fun === false)?ContainerStyle : Object.assign({}, ContainerStyle, {fill:'#C9143B',stroke:'black'})}  />
					 <g
							id="g1034">
						 <text

								style={mainHeaderStyle}
								x="164.78622"
								y="234.047"
								id="text957"><tspan

									x="164.78622"
									y="234.047"
									id="tspan955"
									   ><tspan
										x="164.78622"
										y="234.047"
										style={(this.props.fun === false)?mainHeaderStyle : Object.assign({}, mainHeaderStyle, {fill:'white',fontFamily:mainCursiveStyle})}
										id="tspan953">Jest</tspan></tspan></text>
						 <text

								style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
								x="154.64896"
								y="239.8483"
								id="text951"><tspan

									x="154.64896"
									y="239.8483"
									id="tspan949"
									   ><tspan
										x="145.64896"
										y="239.8483"
										style={(this.props.fun === false)?subHeaderStyle : Object.assign({}, subHeaderStyle, {fill:'white',fontFamily:cursiveStyle,letterSpacing:'0.02rem'})}
										id="tspan947">TDD & Snapshot Testing</tspan></tspan></text>
					 </g>
				 </g>
			  </g>
			</svg>


		);
	}
}

export default Practice;
