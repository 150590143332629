import React from 'react';
import styles from '../sass/components/awardsSection.scss';
import Section from './section';

export default class AwardsSection extends React.Component{
	constructor(props){
		super(props);
	}

	render() {
		if(this.props.layout === 'professional'){
			return <div className='mainAwardsSectionContainer'>
				<Section name='Awards' layout={this.props.layout} />
				{Object.keys(this.props.awards).map((award,index) =>{
					return <div className='awardsSectionContainer' key={'award' + index}>
						<div className='AwardsSectionDesign'>
							<div className='AwardsSectionDesignBox'> </div>
							<h4> {this.props.awards[award][0]} </h4>
						</div>
						<h5> {this.props.awards[award][1]} </h5>
					</div>;
				})}
			</div>;
		} else{
			return <div className='mainAwardsSectionContainerFun'>
				<Section name='Awards' layout={this.props.layout} />
				{Object.keys(this.props.awards).map((award,index) =>{
					return <div className='awardsSectionContainerFun'  key={'award' + index}>
						<h4> {this.props.awards[award][0]} </h4>
						<h5>  </h5>
					</div>;
				})}
			</div>;
		}
	}
}
