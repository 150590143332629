import React from 'react';
// import styles from '../css/interests.css';
import Section from './section';

let dummyInterestList = ['Solving and Analysing problems',
	'Tinkering with electronics', '"Just do it" - Nike',
	'New Technologies', 'Sports', 'Universe'];

export default class Interests extends React.Component {
	constructor(props){
		super(props);

		this.showDummyData = this.showDummyData.bind(this);
		this.showData = this.showData.bind(this);
	}
	showDummyData(){
		let listLength = (dummyInterestList).length;
		return <div className='interestItems'>
			{( dummyInterestList).map((element, index) => {
				if(listLength === (index+1)) {
					return <span key={index}> {element}  </span>;
				} else {
					return <span key={index}> {element} | </span>;
				}
			})}
		</div>;
	}
	showData(){
		let listLength = (this.props.interestList).length;
		return <div className='interestItems'>
			{(this.props.interestList).map((element, index) => {
				if(listLength === (index+1)) {
					return <span key={index}> {element}  </span>;
				} else {
					return <span key={index}> {element} | </span>;
				}
			})}
		</div>;
	}
	render() {

		return <div className='dummyContainer'>
			<div className='interestContainer'>
			<Section name='Interests' />
			{(typeof this.props.interestList === 'undefined') ? this.showDummyData() : this.showData()}
		</div>
		</div>;
	}
}
