import React from 'react';
import styles from  '../../../sass/components/myStrengths.scss';
import Strengths from './strengths';
import Practice from './practice';
const MyStrengths = (props) =>{
	//	<Strengths fun={false}/>
	if(props.props.layout.layout === 'professional'){
		return <div className='section3'>

			<div className='myStrengthsPart1'>
				<h1> <b style={{color:'#001A2E'}}>My</b> Strengths </h1>

			</div>
			<div className='myStrengthsPart2'>
				<Practice fun={false}/>
			</div>
		</div>;
	} else {
		return <div className='section3fun'>
			<div className='myStrengthsPart1'>
				<h1> <b style={{color:'#001A2E',	fontFamily:'Helvetica'}}>My</b> Strengths </h1>
			</div>
			<div className='myStrengthsPart2'>
				<Practice fun={true}/>
			</div>
		</div>;
	}

};

export default MyStrengths;
