import React from 'react';
import styles from  '../../../sass/components/introSection.scss';
import Glass from './glass';
import AnimatedGlasses from  './animatedGlasses';

const IntroSection = (props) =>{
	if(props.props.layout.layout === 'professional'){
		return (<div className='section1' id='section1'>
			<div className='part1'>
				<Glass width='100%' frame='#FF9800' eyeBorder='#FFFFFF'/>
			</div>
			<div className='part2'>
				<h1 className='mainIntroHeader'> FRONTEND ENGINEER</h1>
				<p>My passion is taking your ideas and painting them onto the web canvas</p>
			</div>
		</div>);
	} else {
		return (<div className='section1Fun' id='section1'>
			<div className='part1'>
				<AnimatedGlasses animateGlasses ={props.animateGlasses}/>
			</div>
			<div className='part2'>
				<h1 className='mainIntroHeader'> FRONTEND ENGINEER</h1>
				<p>My passion is taking your ideas and painting them onto the web canvas</p>
			</div>
		</div>);
	}
};

export default IntroSection;
