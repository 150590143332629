import React from 'react';
import styles from  '../../../sass/components/helloWorld.scss';
import HelloWorld from './helloWorld';
import TypedDescription from './typedDescription';

const WhoAmI = (props) =>{

	if(props.props.layout.layout === 'professional'){
		return <div className='section2'>
			<div className='whoAmIpart1'>
				<p> I am a hardworking, determined individual who is convinced
					that <span style={{fontWeight:'bolder', color:'#ff9701'}}>a positive attitude </span> along with a
					<span style={{fontWeight:'bolder', color:'#ff9701'}} > tinge of creativity  </span> is the
					recipe to success!
				</p>
			</div>
			<div className='whoAmIpart2'>
				<HelloWorld animateHelloWorld = {props.animateHelloWorld} />
			</div>
		</div>;
	} else {
		return <div className='section2Fun'>
			<div className='whoAmIpart1'>
				<TypedDescription />
			</div>
			<div className='whoAmIpart2'>
				<p> Let me <b style={{color:'#FF9800', fontFamily:'londrina solid'}}>introduce</b> myself</p>
			</div>

		</div>;
	}

};

export default WhoAmI;
