import React from 'react';

let professionalObj ={
	borderRadius:'50%',
	position:'relative',
	border:'solid 0.2rem pink'
};

let funObj = {
	borderRadius:'10rem',
	border:'solid 0.2rem yellow'
};
class Glass extends React.Component {
	constructor(props){
		super(props);
	}

	render() {
		return (
			<svg className={this.props.showProfessional?'profilePic' : 'profilePic'} width={this.props.width} viewBox="0 0 180 180"  >
				<path d="M75,120 A30,50 0 0,0 90 130"
								  stroke='black' strokeWidth="3"  stroke={this.props.frame} fill='none'/>
				<path d='M90,130 A30,90 0 0,0 105 120'
								 stroke={this.props.frame} fill='none' strokeWidth="3"/>
				<rect x="10" y="87" width="20" height="4" fill={this.props.frame}/>
				<rect x="79" y="87" width="21" height="5" fill={this.props.frame} />
				<rect x="148" y="87" width="21" height="5"  fill={this.props.frame}/>
				<ellipse  cx="55" cy="87" rx="25" ry="23" fill="white" stroke={this.props.frame} fill='transparent' strokeWidth="6"/>
				<ellipse  cx="65" cy="87" rx="5" ry='5.5' stroke={this.props.eyeBorder} fill="black"  />

				<ellipse  cx="124" cy="87" rx="25" ry="23" fill="white" stroke={this.props.frame} fill='none' strokeWidth="6"/>
				<ellipse  cx="114" cy="87" rx="5" ry='5.5' stroke={this.props.eyeBorder}fill="black" />

			</svg>
		);
	}
}

export default Glass;
