import React from 'react';
import Projects from './Projects.js';
import store from '../../store/store.js';
import {connect} from 'react-redux';

const mapStateToProps = function(store) {
	return {
		layout: store.layout
	};
};

class ProjectsContainer extends React.Component{
	constructor(props){
		super(props);
		this.changeLayout = this.changeLayout.bind(this);
	}

	changeLayout(layoutObj){
		console.log('PROJECTS CONTAINER');
		console.log(store.getState());

		console.log(layoutObj.layout);
		store.dispatch({
			type:'CHANGE LAYOUT',
			layout:layoutObj.layout
		});
	}

	render(){
		return <Projects props={this.props} changeLayout={this.changeLayout}/>
	}
}


export default connect(mapStateToProps)( ProjectsContainer);
