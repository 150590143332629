let ProjectsData1  =[
	['ResumeBuilder- Give us the resume content and get a highly responsive, well-designed resume', 'React, HTML5, CSS3,Jest', '/Resume'],
	['Lassonde Student Government - Launch Video', 'Screenflow, photoshop','/LSGVideo'],
	['Souly4Good - IntroVideo', 'Screenflow, photoshop','/SoulyVideo'],
];


let ResumeBuilder = {
	title: 'ResumeBuilder',
	subTitle: 'A creative outlet',
	description: 'This tool transforms the resume data into well designed, resposive web html page.',
	technicalSkills: [ 'React', 'Responsive Design','CSS3', 'HTML5', 'Saas'],
	link:'/Resume'
};

let PortfolioWebsite = {
	link:'/Resume',
	title: 'PortfolioWebSite',
	subTitle: 'Initial Version',
	description: 'My design skills grew over time. Comparison between current website and this project is a testament',
	technicalSkills: [ 'React', 'Responsive Design','CSS3', 'HTML5', 'Sass', 'React Router 4'],
};

let ResumeLayout = {
	link:'/Resume',
	title: 'ResumeLayout',
	subTitle: 'CSS Grid',
	description: 'The initial version of the resume layout was dynamically created for CSS grid framework. This design was not a performant solution and thus switched over to creating the design using CSS flexbox',
	technicalSkills: ['CSSGrid', 'React', 'Responsive Design','CSS3', 'HTML5', 'Sass', 'React Router 4', 'Drag and Drop'],
};
let firstReduxProject = {
	link:'/Resume',
	title: 'Posts',
	subTitle: 'first Redux Project',
	description: 'This the first project on which I tested my knowledge about Redux project. Redux is the way to go. Never looked back',
	technicalSkills: ['CSSGrid','Redux', 'React', 'Responsive Design','CSS3', 'HTML5',],
};

let Souly4Good = {
	link:'/Resume',
	title: 'Souly4Good',
	subTitle: 'Intro Video',
	description: 'I created this video to raise awareness about the startup and fundraised 5k. It was highly appreciated',
	technicalSkills: ['Screenflow','Photoshop',],

};

let LSGvideo = {
	link:'/Resume',
	title: 'Student Government',
	subTitle: 'Launch Video',
	description: 'I created this video to foster unity amongst Lassonde Students and promote the idea that UNITY IS STRENGTH',
	technicalSkills: ['Screenflow','Photoshop',],

};
let ProjectsData = [ResumeBuilder, ResumeLayout, PortfolioWebsite, Souly4Good,  LSGvideo];

export default ProjectsData;
