import React from 'react';
import styles from  '../../../sass/components/whoAmI.scss';

let styleObj = {
	opacity:1,
	animation : 'typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite'
};
const HelloWorld = (props) => {
	return <div className='helloWorld' id='helloWorld'>
		<h1 style={styleObj	} > hello world </h1>
	</div>;
};

export default HelloWorld;
