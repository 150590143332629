import React from 'react';
import PropTypes from 'prop-types';
import Manjeet from '../img/manjeet.jpg';
import Profile from './profile';
import ProfileContact from './profileContact';
import {personInfoArray, contactInfoObject} from './data.js';

import styles from '../sass/components/resumeHeader.scss';

let dummyPersonInfoArray = ['First Name',
	'Potato Engineer',
	'Twinkle twinkle little star'];

let dummyContactInfoObject = {Email:'potato@gmail.com',
	Tel:'647 826 7126',
	Location:'Zoo Toronto, ON',
	LinkedIn: 'www.linkedin.com/in/minion'};

export default class Header extends React.Component{
	constructor(props){
		super(props);
		this.showHeader = this.showHeader.bind(this);
		this.showDummyData = this.showDummyData.bind(this);
		this.state={className:'Header', currentLayout:''};
	}

	showHeader(){
		return <div className={(this.props.layout === 'professional')? 'Header' : 'HeaderFun'}>
			<div className="headerName">
				<Profile pic={Manjeet} personInfoArray={this.props.headerContainer.personInfoArray} layout={this.props.layout}/>
			</div>

			<div className="headerContact">
				<ProfileContact contactInfoObject={this.props.headerContainer.contactInfoObject} layout={this.props.layout}/>
			</div>
		</div>;
	}

	showDummyData(){
		return <div className={this.state.className}>
			<div className="headerName">
				<Profile pic={Manjeet} personInfoArray={dummyPersonInfoArray} layout={this.props.layout} />
			</div>

			<div className="headerContact">
				<ProfileContact contactInfoObject={dummyContactInfoObject} layout={this.props.layout}/>
			</div>
		</div>;
	}

	updateLayout = (propsLayout)=>{
		if(this.state.layout!== propsLayout){
			this.setState({
				className:((this.props.layout === 'professional')? 'Header' : 'HeaderFun'),
				layout: this.props.layout
			});
		}
	}

	render() {
		//this.updateLayout(this.props.layout);
		return  <div className='dummyContainer'> {(typeof this.props.headerContainer === 'undefined')? this.showDummyData(): this.showHeader()}</div>;
	}
}

Header.propTypes = {
/* Array consists of :  'name', 'position', 'shortStatement','photo'*/
	'personInfo': PropTypes.array,
	/* Array consists of :  'email', 'phone', 'linkedIn, Address'*/
	'contactInfoArray': PropTypes.array,
};
