import React from 'react';
import styles from '../sass/components/awards.scss';
import Section from './section';

export default class Awards extends React.Component{
	constructor(props){
		super(props);
		this.showAwards = this.showAwards.bind(this);
	}
	showAwards(){
		return <span  className={this.props.layout === 'professional'? 'awardsContainer'  :'awardsContainerFun'}>
			<h3> Awards </h3>
			{(this.props.awardList).map((element,index) => {
				return <li key={index}>{element}</li>;
			})}
		</span>
	}
	render() {
		return <div>
			{(this.props.awardList).length === 0 ? '': this.showAwards()}
		</div>
	}
}
