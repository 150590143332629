import React from 'react';
// /import styles from '../css/profileContact.css';
import DummyData from './dummy';

import Mail from '../sass/svg/mail.js';
import Phone from '../sass/svg/phone.js';
import Location from '../sass/svg/location.js';
import LinkedIn from '../sass/svg/linkedIn.js';

export default class ProfileContact extends React.Component {
	render() {
		return <div className={(this.props.layout === 'professional'? 'profileContact' : 'profileContactFun')}>

			{(Object.keys(this.props.contactInfoObject)).map((element) => {
				let tag;
				switch(element){
				case 'Email':
					return <div className={'profile'+ element} key={'profile'+ element}>
						<a href={'mailto:' + this.props.contactInfoObject[element]}
							target="_blank">
							<DummyData value={this.props.contactInfoObject[element]}
								name={element}/>
						</a>
						<Mail width='24px'/>
					</div>;
				case 'Tel':
					return <div className={'profile'+ element} key={'profile'+ element}>
						<a >
							<DummyData value={this.props.contactInfoObject[element]}
								name={element}/>
						</a>
						<Phone width='25px'/>
					</div>;
				case 'LinkedIn':
					return <div className={'profile'+ element} key={'profile'+ element}>
						<a href={'https://' + this.props.contactInfoObject[element]} target="_blank">
							<DummyData value={this.props.contactInfoObject[element]} name={element}/>
						</a>
						<LinkedIn width='19px'/>
					</div>;
				case 'Location' :
					return <div className={'profile'+ element} key={'profile'+ element}>
						<a>
							<DummyData value={this.props.contactInfoObject[element]} name={element}/>
						</a>
						<Location width='19px'/>
					</div>;
				}
			})}
		</div>;

	}
}
