import React from 'react';
import Resume from './resume';
import store from '../../store/store.js';
import {connect} from 'react-redux';
import {asyncActionChangeLayout} from '../../store/layoutAction.js';

const mapStateToProps = function(store) {
	return {
		layout: store.layout
	};
};

class ResumeContainer extends React.Component{
	constructor(props){
		super(props);
		this.changeLayout = this.changeLayout.bind(this);
	}

	changeLayout(layoutObj){
		store.dispatch(
			asyncActionChangeLayout(layoutObj.layout,
				document.getElementById('documentFontLink')
			));
	}

	render(){
		return <Resume props={this.props} changeLayout={this.changeLayout}/>;
	}
}


export default connect(mapStateToProps)( ResumeContainer);
