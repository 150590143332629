import React from 'react';
import styles from '../../../sass/components/animatedGlasses.scss';

let count = 0;

let commonStyle={
	fill:'none',
	stroke:'#000000',
	strokeWidth:'5',
};

let bigCircleAnimate = {
	fill:'none',
	stroke:'#000000',
	strokeWidth:'5',
	opacity:'1',
	animation: 'dash 20s linear',
};

let smallCircle={
	opacity: '1',
	animation: 'appear 2s linear'
};

let rectAnimate ={
	fill:'none',
	stroke:'#000000',
	strokeWidth:'5',
	animation: 'rect 3s linear'
};
class AnimatedGlasses extends React.Component {
	constructor(props){
		super(props);
	}

	render() {
		if(count<1){
			if(((window.pageYOffset===0
				&& !this.props.animateGlasses))
				|| this.props.animateGlasses) {
				count+=1;
			}
		}

		return (
			<svg className='circle' width='100%' viewBox="0 0 200 85" >
				<path
					id="smile2"
		     	d="M 100,80 A 30,50 0 0 1 85,70 m 30,0 a 30,90 0 0 1 -15,10" />
		  <path
		     style={{fill:'#000000',stroke:'#000000'}}
		     d="M 80,37 A 5,5.5 0 0 1 75,42.5 5,5.5 0 0 1 70,37 5,5.5 0 0 1 75,31.5 5,5.5 0 0 1 80,37 Z"
		     id="ellipse86" />
		  <path
		     style={{fill:'#000000',stroke:'#000000'}}
		     d="m 129,37 a 5,5.5 0 0 1 -5,5.5 5,5.5 0 0 1 -5,-5.5 5,5.5 0 0 1 5,-5.5 5,5.5 0 0 1 5,5.5 z"
		     id="ellipse90" />
		  <path
		     style={count==1? bigCircleAnimate: commonStyle}
		     d="m 159,37 c 0,12.702549 -11.19288,23 -25,23 -7.05107,0 -13.41989,-2.685901 -17.96484,-7.005859 C 111.68038,48.85493 109,43.215567 109,37 c 0,-12.702549 11.19288,-23 25,-23 13.80712,0 25,10.297451 25,23 z"
		     id="bigCircle2" />
		  <path
		     style={count==1? bigCircleAnimate: commonStyle}
		     d="m 37.8125,39.025391 c 3.1e-5,12.70386 11.193524,23.001922 25.001953,23.001953 7.507822,-1.7e-5 14.243168,-3.044969 18.826172,-7.865235 3.846082,-4.045198 6.175768,-9.340112 6.175781,-15.136718 C 87.816375,26.321531 76.622884,16.023467 62.814453,16.023438 49.006024,16.023467 37.812531,26.321531 37.8125,39.025391"
		     id="bigCircle2"
		     />
		  <path
		     style={count==1? rectAnimate:commonStyle}
		     d="m 14.75,39.5 h 21 z"
		     id="rect1"
 			 />
		  <path
		     style={count==1? rectAnimate:commonStyle}
		     d="M 181.18164,39.5 H 160.35742 Z"
		     id="rect2" />
		  <path
		     style={count==1? rectAnimate:commonStyle}
		     d="m 107.875,39.59961 h -20.5 z"
		     id="rect3"
		     />
			</svg>
		);
	}
}

export default AnimatedGlasses;
