import React from 'react';
import styles from '../sass/components/additionalSkills.scss';
import Section from './section';
import DummyData from './dummy';
import Puzzle from './puzzle';

let dummyOtherSkills = {
	1 : {category: 'Languages', list:'Java, C, C#, GoLang, Phython(script), Javascript, JQuery, Unity, HTML, CSS '},
	2 : {category: 'Databases', list:'DynamoDB, GoogleCloud, MySQL, Redis, Memcache, Oracle, DB2'},
	3 : {category: 'OS/Cloud', list:'AWS,GCS, Linux, Unix, Windows'},
	4 : {category: 'Soft Skills', list:' Team-Player, Project Management, Analytical'},
	5 : {category: 'Services/Methodologies', list:'Pubrub, GIT, Selenium, Yii, Firebase, TDDm, Agile, Scrum, nginx, CyberSecurity'}
};


export default class AdditionalSkills extends React.Component{
	showData(){

		return <div className={this.props.layout === 'professional'? 'otherSkillContainer' : 'otherSkillContainerFun'} >
			<Section name='Technical Skills' layout={this.props.layout}/>
			{(Object.keys(this.props.skills)).map(element => {
				if(this.props.layout === 'professional'){
					return <div className='skillList' key ={element}>
						<div className='additionalSkillsDesign'>
							<div className='AdditionalSkillsDesignBox'> </div>
							<h3 className='AdditionalSkillsDesignContent'> <DummyData value={((this.props.skills)[element]).category} name='Category'/> </h3>
						</div>
						<p><DummyData value={((this.props.skills)[element]).list} name='description'/> </p>
					</div>;
				} else{
					return <div className='skillListFun' key ={element}>
						<Puzzle title={<DummyData value={((this.props.skills)[element]).category} name='Category'/>}
							description={<DummyData value={((this.props.skills)[element]).list} name='description'/>}
						/>;
					</div>;
				}

			})}
		</div>;
	}

	showDummyData(){
		return <div className='otherSkillContainer'>
			<Section name='Technical Skills' layout={this.props.layout}/>
			{(Object.keys(dummyOtherSkills)).map(element => {
				return <div className='skillList' key ={element}>
					<h3 className='category'> {(dummyOtherSkills[element]).category} </h3>
					<p>{(dummyOtherSkills[element]).list} </p>
				</div>;
			})}
		</div>;
	}

	render() {
		return <div className='dummyContainer'>
			{(typeof this.props.skills === 'undefined')? this.showDummyData() : this.showData()}
		</div>;
	}
}
