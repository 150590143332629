import React from 'react';
import styles from '../sass/components/puzzle.scss';
import DummyData from './dummy';
export default class Puzzle extends React.Component{
	constructor(props){
		super(props);
	}

	render() {
		return <div className='puzzleContainer'>
			<div className='headPuzzle'>
				<div  className='headCircle'></div>
				<h3  className='headPuzzleContent'> {this.props.title} </h3>

			</div>

			<div  className='bodyPuzzle'>
				<div  className='bodyCircle1'> </div>
				<p className='bodyPuzzleContent'> {this.props.description} </p>
			</div>
		</div>
	}
}
