import React from 'react';
import IntroSection from './components/introSection';
import WhoAmI from './components/whoAmI';
import MyStrengths from './components/myStrengths';
import WebsiteHeader from './components/webSiteHeader';

const Landing = (props)=>{
	let data = props.props;
	return <div className='landing'>
		<WebsiteHeader changeLayout={props.changeLayout} layout={data.layout}/>
		<IntroSection props={data} animateGlasses={props.animateGlasses}/>
		<WhoAmI props={data} animateHelloWorld={props.animateHelloWorld}/>
		<MyStrengths props={data} />
	</div>;
};

export default Landing;