export const calculateNewState = (windowWidth,ONECOLUMNWIDTH, activeState) =>{
	if(activeState === 'firstRender'){
		if(windowWidth > ONECOLUMNWIDTH){
			return { active: 'twoColumnGrid',width: windowWidth};
		}else{
			return { active: 'oneColumnGrid', width: windowWidth};
		}
	}
	if(windowWidth > ONECOLUMNWIDTH && activeState === 'oneColumnGrid'){
		return { active: 'twoColumnGrid', width: windowWidth};
	} else if((windowWidth <= ONECOLUMNWIDTH && activeState === 'twoColumnGrid')){
		return { active: 'oneColumnGrid',width: windowWidth};
	} else  return false;

};


export const layoutHasBeenUpdated = (doubleColumn1, stateDoubleColumn1, doubleColumn2,stateDoubleColumn2, singleColumn, stateSingleColumn, MaxArraySize) => {
	let doubleColumn1Length = doubleColumn1.length;
	let doubleColumn2Length = doubleColumn2.length;
	if(doubleColumn1Length !== stateDoubleColumn1
		|| doubleColumn2Length !== stateDoubleColumn2){
		return true;
	} else{
		for(let i=0; i<MaxArraySize;i++){
			if(i<doubleColumn1Length){
				if(doubleColumn1[i] !== stateDoubleColumn1[i] ){
					return true;
				}
			}
			if(i<doubleColumn2Length){
				if(doubleColumn2[i] !== stateDoubleColumn2[i] ){
					return true;
				}
			}
			if(singleColumn[i] !== stateSingleColumn[i]){
				return true;
			}
		}
		return false;
	}

};
// module.exports = {calculateNewState, layoutHasBeenUpdated};
