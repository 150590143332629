import React, {useState, useEffect} from 'react';
import Landing from './landing';
import store from '../../store/store.js';
import {connect} from 'react-redux';
import {asyncActionChangeLayout} from '../../store/layoutAction.js';

const mapStateToProps = function(store) {
	return {
		layout: store.layout
	};
};


  
const LandingContainer = (props) => {
	const [helloWorldAnimationShown, setHelloWorldAnimationShow] = useState(false);
	const [glassesAnimationShown, setGlassesAnimationShown] = useState(false);
	const [stopListeningScroll, setStopListeningScroll] = useState(false);
	
	function showAnimation(scrollYposition) {
	var introSection = document.getElementById('section1');

	if(!stopListeningScroll && introSection){
		let height = introSection.clientHeight;
		if(!glassesAnimationShown && props.layout.layout === 'personal'){
			if (scrollYposition < height/3){
				setGlassesAnimationShown(true);
			}
		}else if (!helloWorldAnimationShown && props.layout.layout === 'professional'){
			if(scrollYposition > (height*(2/3))){
				setHelloWorldAnimationShow(true)
			}
		}else if(glassesAnimationShown && helloWorldAnimationShown){
			setStopListeningScroll(true);
		}

		setTimeout(()=>{
			showAnimation(window.pageYOffset);
		},250);
	}}
	function changeLayout(layoutObj){
		store.dispatch(
			asyncActionChangeLayout(layoutObj.layout,
				document.getElementById('documentFontLink')
			));
	}
	useEffect(() => {
		showAnimation(window.pageYOffset);
	}, [])
	
	return <Landing props={props}
			changeLayout={changeLayout}
			animateHelloWorld={helloWorldAnimationShown}
			animateGlasses = {glassesAnimationShown}
		/>;
	
}

export default connect(mapStateToProps)( LandingContainer);
