import React from 'react';
//import {skills} from './data.js';
import styles from '../sass/components/skills.scss';
import Section from './section';

let dummySkills = {
	1: {skill: 'StupidJokes',level : 4},
	2: {skill: 'Dance',level : 1},
	3: {skill: 'Talking',level : 5},
	4: {skill: 'React',level : 3},
	5: {skill: 'Bah',level : 2},
};

const MaxLevel = 5;
export default class Skills extends React.Component {
	constructor(props){
		super(props);
		this.determineSkillBarWidth = this.determineSkillBarWidth.bind(this);
		this.showData = this.showData.bind(this);
		this.showDummyData  = this.showDummyData.bind(this);
	}

	determineSkillBarWidth(level){
		let widthPercentage = (level/MaxLevel) * 100;
		return widthPercentage;
	}

	showData(){
		return (Object.keys(this.props.skillList)).map((element) => {
			return <div className={'skill' + element} key={'skill' + element}
				style={{width:(this.determineSkillBarWidth((this.props.skillList[element]).level)+ '%')}}>
				<h3> {(this.props.skillList[element]).skill} </h3>
			</div>;
		});

	}

	showDummyData(){
		return (Object.keys(dummySkills)).map((element) => {
			return <div className={'skill' + element} key={'skill' + element}
				style={{width:(this.determineSkillBarWidth((dummySkills[element]).level)+ '%')}}>
				<h3> {(dummySkills[element]).skill} </h3>
			</div>;
		});
	}
	render() {
		return <div className={this.props.layout === 'professional'? 'skillsContainer' : 'skillsContainerFun'}>
			<Section name='Skills/Competencies' layout={this.props.layout}/>
			<div className='barContainer'>
				<div className='scale'>
					<p className='scaleUnit'></p>
					<p className='scaleUnit'></p>
					<p className='scaleUnit'></p>
					<p className='scaleUnit'></p>
					<p className='scaleUnit'></p>
				</div>

				{(typeof this.props.skillList === 'undefined' ||(Object.keys(this.props.skillList)).length === 0)? this.showDummyData() : this.showData()}
			</div>
		</div>;
	}
}
