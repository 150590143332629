import React from 'react';

//import {EducationList} from './data.js';
import PeriodLocation from './PeriodLocation';
import MainSubHeading from './MainSubHeading';
import Awards from './awards';
import styles from '../sass/components/education.scss';
import Section from './section';
import Graduation from '../sass/svg/graduation.js';


export let dummyEducation1 = {
	startDate: '09/2006',
	endDate: '04/2011',
	location: 'Toronto, ON',
	degree: 'Personalised Diploma',
	universityName:'Hogwarts',
	awards:[
		'Barnabus Finkley Prize for Exceptional Spell-Casting',
		'Dangerous” Dai Commemorative Medal',
		'Medal for Magical Merit',
		'Most Charming Smile award'
	]
};

export let dummyEducationList = [dummyEducation1];
export default class Education extends React.Component{
	constructor(props){
		super(props);
		this.showData = this.showData.bind(this);
		this.showDummyData = this.showDummyData.bind(this);
	}

	showData(){
		if(this.props.layout ==='professional'){
			return <div className='educationContainer'>
				<Section name='Education' layout={this.props.layout}/>
				{((this.props.educationList)).map((element,index) => {
					return <div key={index} className='innerEducationContainer'>
						<PeriodLocation startDate={element.startDate}
							endDate={element.endDate}
							location={element.location}
							layout={this.props.layout}/>
						<MainSubHeading mainHeading={element.degree}
							subHeading={element.universityName}
							layout={this.props.layout} />
						<Awards awardList={element.awards}
							layout={this.props.layout}/>
					</div>
				})}
			</div>
		}else{
			return <div className='educationContainerFun'>
				<div className='sectionEducation'>
					<Section name='Education' layout={this.props.layout}/>
				</div>
				<div className='graduationSVGEducation'>
					<Graduation />
				</div>
				<div className='graduationDetail'>
					{((this.props.educationList)).map((element,index) => {
						return <div key={index} className='innerEducationContainer'>
							<PeriodLocation startDate={element.startDate}
								endDate={element.endDate}
								location={element.location}
								layout={this.props.layout}/>
							<MainSubHeading mainHeading={element.degree}
								subHeading={element.universityName}
								layout={this.props.layout} />
						</div>

					})}
				</div>

			</div>
		}

	}

	showDummyData(){
		if(this.props.layout ==='professional'){
			return <div className='educationContainer'>
				<Section name='Education' layout={this.props.layout}/>
				{(dummyEducationList).map((element,index) => {
					return <div key={index} className='innerEducationContainer'>
						<PeriodLocation startDate={element.startDate}
							endDate={element.endDate}
							location={element.location}
							layout={this.props.layout}/>
						<MainSubHeading mainHeading={element.degree}
							subHeading={element.universityName}
							layout={this.props.layout} />
						<Awards awardList={element.awards}
							layout={this.props.layout}/>
					</div>
				})}
			</div>
		}else{
			return <div className='educationContainerFun'>
				<h1> Hello world </h1>
			</div>
		}

	}

	render() {
		return <div className='dummyContainer'>
			{(typeof this.props.educationList === 'undefined') ?  this.showDummyData() :
				this.showData()}
		</div>
	}
}
