import React from 'react';
import styles from  '../../../sass/components/typedDescription.scss';


const TypedDescription = () => {
	let fontStyle ='\'Shadows Into Light Two\', cursive';
	let descriptionArray = ['Hi, I am Manjeet',
		'I love to learn',
		'I believe - Deadends lead to creative solutions',
		'My code is my favorite employee -  it executes my vision at lightening speed'];

	return <div className='typedDescription'>
		<button className='hoverEffect' style={{fontFamily:fontStyle,cursor:'pointer'}} > I LOVE <span  style={{fontFamily:fontStyle, letterSpacing: '0.1rem'}} > to learn</span> </button>
		<button className='hoverEffect'  style={{fontFamily:fontStyle,cursor:'pointer'}} > I BELIEVE <span  style={{fontFamily:fontStyle, letterSpacing: '0.1rem'}} >:that dead ends lead to creative and innovative solutions </span> </button>
		<button className='hoverEffect'  style={{fontFamily:fontStyle,cursor:'pointer'}} > FAVORITE EMPLOYEE <span  style={{fontFamily:fontStyle, letterSpacing: '0.1rem'}} > <b style={{color:'#FF9800'}}>MY CODE:</b> it executes my vision at lightning speed</span> </button>
	</div>;
};

export default TypedDescription;
