import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Header1 from './header1.js';
import {headerContainer, positionList,EducationList,skills, ProjectsList,OtherSkills,InterestList,AwardsList} from './data.js';
import WorkPositionList from './WorkPositionList';
import Education from './education';
import Skills from './skills';
import Projects from './projects';
import AdditionalSkills from './additionalSkills';
import Interests from './interests';
import Modal from './modalUpdate';
import AwardsSection  from './AwardsSection';
import styles from '../sass/components/mainResumeContainter.scss';
//import styles from '../css/flexBox.css';
var flexBoxFunctionObj = require('./FlexBoxFunctions');

let updateResumeLayoutStyle={
	padding: '0',
	background: '#630363',
	color: 'white',
	fontSize: '0.8rem',
	fontFamily: 'Roboto',
	marginBottom: '0.4em',
	fontVariant: 'small-caps',

};
let updateMessageStyle = {
	padding:'0.3em',
	border:'0.05em solid #3F51B5',
	color:'black',
	fontSize:'0.6rem',
	fontFamily:'Roboto',
	textAlign:'center',
};
const ONECOLUMNWIDTH = 750;

export default class FlexBoxLayout extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			active : '',
			width: '',
			doubleColumn1:['mainSkills','mainAdditionalSkills','mainProjects'],
			doubleColumn2:['mainWorkPositionList', 'mainAwardsList','mainEducation'],
			singleColumn :['mainWorkPositionList','mainSkills','mainProjects','mainAdditionalSkills','mainEducation','mainAwardsList',],
			showModal: false,
			updateMessage:false
		};
		this.updateDimensions = this.updateDimensions.bind(this);
		this.update2ColumnGrid = this.update2ColumnGrid.bind(this);
		this.createDoubleColumn = this.createDoubleColumn.bind(this);
		this.updateLayout = this.updateLayout.bind(this);
		this.updateResumeLayout = this.updateResumeLayout.bind(this);
		this.print = this.print.bind(this);
	}

	update2ColumnGrid(){
		let mainResumeContainerStyle = {
			display:'flex',
			flexFlow:'row wrap',
			alignItems: 'flex-start'
		};
		let mainHeaderStyle = {
			flex: '1 0 auto',
			width: '100%',
			order: '1'
		};

		return <div className='mainResumeContainer' style={mainResumeContainerStyle}>
			<div className='mainHeader' style={mainHeaderStyle} >
				<Header1 headerContainer={headerContainer} layout={this.props.props.layout.layout} />
			</div>
			{this.createDoubleColumn(1, this.state.doubleColumn1)}
			{this.createDoubleColumn(2, this.state.doubleColumn2)}
		</div>;
	}

	createDoubleColumn(numColumn, columnArray){
		let orderNum = JSON.stringify(numColumn + 1);
		let order = 1;
		let columnDivStyle = {};
		if( numColumn === 1){
			columnDivStyle = {
				flex: '1',
				order: orderNum,
				marginRight:'2%',
				display:'flex',
				flexFlow:'column nowrap'
			};
		}else{
			columnDivStyle = {
				flex: '1',
				order: orderNum,
				marginLeft:'2%',
				display:'flex',
				flexFlow:'column nowrap'
			};
		}

		return (<div className={'column'+ numColumn + 'MainContainer'} style ={columnDivStyle}>
			{columnArray.map(element =>{
				return this.displayComponent(element, order, 'doubleColumn');
			})}
		</div>);
	}

	update1ColumnGrid(singleColumnArray){
		let order = 1;
		let mainResumeContainerStyle = {
			display:'flex',
			flexFlow:'column nowrap',
			alignItems: 'flex-start'
		};
		let mainHeaderStyle = {
			flex: '1 0 auto',
			width: '100%',
			order: '1'
		};

		return <div className='mainResumeContainer' style={mainResumeContainerStyle}>
			<div className='mainHeader' style={mainHeaderStyle} >
				<Header1 headerContainer={headerContainer}  layout={this.props.props.layout.layout}/>
			</div>
			{singleColumnArray.map(element =>{
				order++;

				return this.displayComponent(element, order, 'singleColumn');
			})}
		</div>;

	}

	displayComponent(elementName, order, column){
		let displayElement;
		let styleObj = {};
		if(column  === 'doubleColumn'){
			styleObj['flex'] = '1';
			styleObj['order'] = order;
		} else{
			styleObj['order'] = order;
			styleObj['flex'] = '1';
			styleObj['width'] = '100%';
		}
		switch(elementName){
		case 'mainProjects':
			displayElement = 	<div className='mainProjects' key='mainProjects' style={styleObj} layout={this.props.props.layout.layout}>
				<Projects projectsList={ProjectsList} layout={this.props.props.layout.layout} />
			</div>;
			break;
		case 'mainSkills':
			displayElement =
			<div className='mainSkills' key='mainSkills' style={styleObj} >
				<Skills skillList={skills}  layout={this.props.props.layout.layout}/>
			</div>; break;
		case 'mainEducation':
			displayElement = <div className='mainEducation' key='mainEducation' style={styleObj} >
				<Education educationList={EducationList} layout={this.props.props.layout.layout}/>
			</div>; break;
		case 'mainAdditionalSkills':
			displayElement = <div className='mainAdditionalSkills' key='mainAdditionalSkills' style={styleObj}>
				<AdditionalSkills skills={OtherSkills}  layout={this.props.props.layout.layout}/>
			</div>;break;
		case 'mainWorkPositionList':
			displayElement = <div className='mainWorkPositionList' key='mainWorkPositionList' style={styleObj}>
				<WorkPositionList jobList={positionList} layout={this.props.props.layout.layout}/>
			</div>; break;
		case 'mainInterestList':
			displayElement = <div className='mainInterestList' key='mainInterestList' style={styleObj} >
				<Interests interestList={InterestList}/>
			</div>; break;
		case 'mainAwardsList':
			displayElement = <div className='mainAwardsList' key='mainAwardsList' style={styleObj} >
				<AwardsSection awards={AwardsList} layout={this.props.props.layout.layout}/>
			</div>; break;
		default :
			displayElement = <div className='errorContainer' key='errorContainer' style={styleObj}>
				<h1> Oopsy. This is incorrect component name</h1>
			</div>; break;
		}
		return displayElement;
	}

	updateDimensions(firstRender){
		if(firstRender === 'firstRender'){
			let resultObj = flexBoxFunctionObj.calculateNewState(   window.innerWidth, ONECOLUMNWIDTH,'firstRender');
			this.setState({active:resultObj.active, width:resultObj.width});
		}else{
			let resultObj = flexBoxFunctionObj.calculateNewState(window.innerWidth,ONECOLUMNWIDTH,this.state.active);
			if(resultObj){
				if(resultObj === 'oneColumnWidth'){
					this.update1ColumnGrid();
				}else{
					this.update2ColumnGrid();
				}
				this.setState({active:resultObj.active, width:resultObj.width});
			}
		}
	}

	updateLayout(){
		this.setState((prevState) =>{
			return {showModal : !prevState.showModal};
		});
	}
	//add listener to componentDid Mount
	componentDidMount(){
		this.updateDimensions('firstRender');
		window.addEventListener('resize', this.updateDimensions.bind(this));
	}
	componentWillUnmount(){
		window.removeEventListener('resize', this.updateDimensions());
	}

	updateResumeLayout(doubleColumn1, doubleColumn2, singleColumn){
		console.log('updateResumeLayout');
		console.log(singleColumn);
		//console.log(`this.state.doubleColumn1 == doubleColumn1 ${this.state.doubleColumn1 == doubleColumn1} ${(this.state.doubleColumn1).toString() == doubleColumn1.toString()}`)
		//determine if user updatedLayout

		let result = flexBoxFunctionObj.layoutHasBeenUpdated(doubleColumn1, this.state.doubleColumn1, doubleColumn2, this.state.doubleColumn2, singleColumn, this.state.singleColumn, singleColumn.length);
		if(result){
			this.setState((prevState) =>{
				return {
					doubleColumn1: prevState.doubleColumn1 = doubleColumn1,
					doubleColumn2: prevState.doubleColumn2 = doubleColumn2,
					singleColumn: prevState.singleColumn = singleColumn,
					showModal:false
				};
			});
			this.showUpdateMessage('Your changes have been propogated');
		} else{
			this.setState((prevState) =>{
				return {
					doubleColumn1: prevState.doubleColumn1 = doubleColumn1,
					doubleColumn2: prevState.doubleColumn2 = doubleColumn2,
					singleColumn: prevState.singleColumn = singleColumn,
					showModal:false,
					updateMessage: false
				};
			});
		 this.showUpdateMessage('No changes have been made');
		}

	}
	print(){
		window.print();
	}
	showUpdateMessage(message){
		this.setState({
			updateMessage:message
		});
		setTimeout(()=>{
			this.setState({updateMessage:false});this.forceUpdate();},5000);
	}
	render() {
		return <div style={{display:'flex', flexFlow:'column nowrap', margin:'0 4%'}} className={this.props.props.layout.layout === 'professional' ? 'updateMainResumeContainer' : 'updateMainResumeContainerFun'}>
			<button onClick={()=>this.print()}  style={{display:'none'}}> Print Resume </button>
			<button style={updateResumeLayoutStyle} onClick={this.updateLayout} style={{display:'none'}}> Update Resume Layout </button>
			{this.state.showModal === true?
				<Modal doubleColumn1={this.state.doubleColumn1}
					doubleColumn2={this.state.doubleColumn2}
					singleColumn={this.state.singleColumn}
					updateResumeLayout={this.updateResumeLayout} /> : ''}
			{this.state.updateMessage !== false ?
				<p style={updateMessageStyle}> {this.state.updateMessage} </p>
				: ''}
			{(this.state.active === 'twoColumnGrid') ?
				this.update2ColumnGrid() :
				this.update1ColumnGrid(this.state.singleColumn)
			}
		</div>;
	}
}
