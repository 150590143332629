import React from 'react';
import styles from '../sass/components/periodLocation.scss';
import DummyData from './dummy';
export default class PeriodLocation extends React.Component {
	render() {
		if(this.props.layout === 'professional'){
			return	<div className='PeriodLocationContainer'>
				<div className='startEndDate'>
					<h3>{<DummyData name='startDate' value={this.props.startDate}/>} - {<DummyData name='endDate' value={this.props.endDate}/>}</h3>
				</div>
				<div className='location'>
					<h3>{<DummyData name='location' value={this.props.location}/>} </h3>
				</div>
			</div>
		} else{
			return	<div className='PeriodLocationContainerFun'>
				<div className='startEndDate'>
					<h3>{<DummyData name='startDate' value={this.props.startDate}/>} - {<DummyData name='endDate' value={this.props.endDate}/>}</h3>
				</div>
			</div>
		}

	}
}
