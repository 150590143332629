import { Route } from 'react-router-dom';
import React from 'react';
import LandingContainer from './Pages/landingContainer';
import ResumeContainer from './Pages/resumeContainer';
import ProjectsContainer from './Pages/projectsContainer';

const WebsiteRoute = () =>{
	return <div>
		<Route exact path="/" component={LandingContainer}/>
		<Route exact path="/home" component={LandingContainer}/>
		<Route exact path="/Resume" component={ResumeContainer}/>
		<Route exact path="/Projects" component={ProjectsContainer}/>
	</div>;
};

export default WebsiteRoute;
