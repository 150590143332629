import React from 'react';
import DummyData from './dummy';
import Ribbon from '../sass/svg/ribbon';
import Glass from './glass';
//<img className="profilePic" src={this.props.pic} alt='Manjeet' />
export default class Profile extends React.Component{
	render() {
		if(this.props.layout === 'professional'){
			return <div className='profile'>
				<div className='pic'>
					<Glass width='100%' frame='#FFFFFF' eyeBorder='#FF9800' showProfessional={true}/>
				</div>
				<div className='profileName'>
					<h1> <DummyData value={this.props.personInfoArray[0]} name='Name'/> </h1>
					<h2> <DummyData value={this.props.personInfoArray[1]} name='Position'/>  </h2>
				</div>
				<div className='profileStatement'>
					<h3> <DummyData value={this.props.personInfoArray[2]} name='OneLiner'/> </h3>
				</div>
			</div>;
		} else{
			return <div className='profileFun'>
				<div className='pic'>
					<Glass width='100%' frame='#FF9800' eyeBorder='#FFFFFF' showProfessional={false}/>
				</div>
				<div className='profileName'>
					<h1> <DummyData value={this.props.personInfoArray[0]} name='Name'/> </h1>
					<Ribbon />
				</div>
				<div className='profileStatement'>
					<h3> <DummyData value={this.props.personInfoArray[2]} name='OneLiner'/> </h3>
				</div>
			</div>;
		}

	}
}
