import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import store from '../store/store';
import {Provider} from 'react-redux';

export const PortfolioWebsite = () =>{
	return <App />;
};

// ReactDOM.render(
// 	<Provider store={store}>
// 		<PortfolioWebsite />
// 	</Provider>,
// 	document.getElementById('app'));
