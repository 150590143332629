import React from 'react';
import styles from '../../sass/components/description.scss';
import DummyData from '../dummy';

export default class Description extends React.Component{
	render() {

		return <div className={this.props.layout === 'professional'? 'descriptionContainer' : 'descriptionContainerFun'}>
			<h3 className='tasksAchievements'>Task/Achievements</h3>
			<ul>
				{((this.props.content).length === 0) ? <DummyData name='description' value=''/> :
					(this.props.content).map((element,index) => {
						return <li key={element + index}> <span> {element} </span> </li>;
					})}
			</ul>
		</div>
	}
}
