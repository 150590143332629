import React from 'react';
// import ReactDOM from 'react-dom';
// import styles from '../css/modalUpdate.css';
/* eslint-disable no-restricted-globals */
export default class Modal extends React.Component{
	constructor(props){
		super(props);
		let newDoubleColumn1 = this.props.doubleColumn1.slice(0,this.props.doubleColumn1.length);
		let newDoubleColumn2 =  this.props.doubleColumn2.slice(0,this.props.doubleColumn2.length);
		let newSingleColumn1 =  this.props.singleColumn.slice(0,this.props.singleColumn.length);
		this.state= {
			'doubleColumn1':this.props.doubleColumn1,
			'doubleColumn1updated': newDoubleColumn1,
			'doubleColumn2' : this.props.doubleColumn2,
			'doubleColumn2updated': newDoubleColumn2,
			'singleColumn1' : this.props.singleColumn,
			'singleColumnUpdated' : newSingleColumn1,
		};
		this.display = this.display.bind(this);
		this.showDivElements = this.showDivElements.bind(this);
		this.updateLayout = this.updateLayout.bind(this);
		this.updateElementLocation = this.updateElementLocation.bind(this);
		this.showDivElementsSingle  = this.showDivElementsSingle.bind(this);
	}

	showDivElements(column){
		return column.map(element =>{
			return <div draggable='true'
				className={'containerElements'}
				onDragStart={(e)=>{this.drag(e);}}
				id={element}>
				{this.display(element)}
			</div>
		});
	}

	showDivElementsSingle(column){
		console.log('showDivElmentsSingle');
		console.log(column);
		return column.map(element =>{
			return <div draggable='true'
				className={'containerElements'}
				onDragStart={(e)=>{this.drag(e);}}
				id={element + 'Single'}>
				{this.display(element)}
			</div>
		});
	}


	display(elementName){
		let displayContent;
		switch(elementName){
		case 'mainProjects': displayContent='Projects';break;
		case 'mainSkills': displayContent='Skills/Competencies';break;
		case 'mainEducation': displayContent='Education';break;
		case 'mainAdditionalSkills': displayContent='Other Skills';break;
		case 'mainWorkPositionList': displayContent='Work Experience';break;
		case 'mainInterestList' : displayContent='Interests';break;
		}
		return displayContent;
	}

	allowDrop(ev) {
		ev.preventDefault();
	}

	drag(ev) {
		ev.dataTransfer.setData('text', ev.target.id);
	}

	dropDiv(e){
		var data = e.dataTransfer.getData('text');
		console.log(`parent ${parent} e ${e.target.id} data ${data}`);
		if(e.target.id === 'div1' || e.target.id ==='div2'){
			e.target.appendChild(document.getElementById(data));
		} else{
			(document.getElementById(e.target.id).parentNode).insertBefore(document.getElementById(data),document.getElementById(e.target.id));
		}

		//e.target.insertAdjacentHTML('beforebegin',document.getElementById(data));
		console.log(this.refs);
	}

	dropDivSingleColumn(e){
		console.log('in the dropDivSingleColumn');
		var data = e.dataTransfer.getData('text');
		
		let parent = (document.getElementById(e.target.id).parentNode);
		if(parent.id === 'div3'){
			console.log(`updated`);
			parent.insertBefore(document.getElementById(data),document.getElementById(e.target.id));
		}


	}

	updateLayout(){
		let doubleColumn1 = [];
		let doubleColumn2 = [];
		let singleColumn = [];
		Object.keys(this.refs).map(element =>{
			if(element === 'div1'){
				this.updateElementLocation(element, doubleColumn1);
			}else if( element === 'div2'){
				this.updateElementLocation(element, doubleColumn2);
			}else if(element === 'div3'){
				this.updateElementLocation(element, singleColumn);
			}
		});
		this.props.updateResumeLayout(doubleColumn1, doubleColumn2, singleColumn);
	}

	updateElementLocation(container, containerArray){
		let i=0;
		let removeSingle = (container === 'div3') ? true : false;
		(Object.keys((this.refs[container]).children)).map(childElement =>{
			if (childElement.length >3){
				if(removeSingle){
					containerArray[i] = childElement.slice(0, childElement.length-6);
				}else{
					containerArray[i] = childElement;
				}
				i++;
			}
		});
	}

	render(){

		return <div className='modalContainer'>
			<button className="submitLayoutChanges" onClick={()=>{this.updateLayout()}}> Submit Layout Changes </button>
			<div className='DoubleColumnLayout'>
				<div className='ModalLayoutDescription'>
					<h1> Update 2-Column Layout </h1>
					<p> Drag elements on component names to rearrange order or drag them to your preferrered column</p>
				</div>
				<div id="div1" ref='div1' onDrop={(e)=>{console.log(`on Drop e.target.id ${e.target.id}`); this.dropDiv(e);}} onDragOver={(e)=>{console.log(`onDrgaOver e.target.id ${e.target.id}`); this.allowDrop(e);}}>
					<h1 className='containerHeader'> Column1 </h1>
					{this.showDivElements(this.state.doubleColumn1updated)}
				</div>

				<div id="div2" ref='div2' onDrop={(e)=>{console.log(`on Drop e.target.id ${e.target.id}`); this.dropDiv(e);}} onDragOver={(e)=>{console.log(`onDrgaOver e.target.id ${e.target.id}`); this.allowDrop(e);}}>
					<h1 className='containerHeader'> Column2 </h1>
					{this.showDivElements(this.state.doubleColumn2updated)}
				</div>

			</div>

			<div className='singleColumnLayout'>
				<div className="ModalLayoutDescription">
					<h1> Update 1-Column Layout </h1>
					<p> Drag elements on component names to rearrange order </p>
				</div>
				<div id="div3" ref='div3' onDrop={(e)=>{console.log(`on Drop e.target.id ${e.target.id}`); this.dropDivSingleColumn(e);}} onDragOver={(e)=>{console.log(`onDrgaOver e.target.id ${e.target.id}`); this.allowDrop(e);}}>
					<h1 className='containerHeader'> Column1 </h1>
					{this.showDivElementsSingle(this.state.singleColumnUpdated)}
				</div>
			</div>

		</div>
	}
}

/*
ReactDOM.render(<Modal />, document.getElementById('app'))
Resume.PropTypes = {
	'message': PropTypes.string.isRequired
};*/
