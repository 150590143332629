import React from 'react';
import PeriodLocation from '../PeriodLocation';
import MainSubHeading from '../MainSubHeading.js';
import Description from './Description.js';
import styles from '../../sass/components/workPosition.scss';
import DummyData from '../dummy';

export default class WorkPosition extends React.Component{

	render() {
		if(this.props.layout === 'professional'){
			return <div className="inlineData" style={{display: 'block'}}>
				<PeriodLocation  startDate={this.props.data.startDate}
					endDate={this.props.data.endDate}
					location={this.props.data.location}
					layout={this.props.layout}/>
				<MainSubHeading
					mainHeading={this.props.data.positionTitle}
					subHeading={this.props.data.companyName}
					layout={this.props.layout}/>
				<Description content={this.props.data.description}  layout={this.props.layout}/>
			</div>;

		}else{
			return <span className='workPositionCubeDiv'>
				<h3 className='cubeDate'>{<DummyData name='startDate' value={this.props.data.startDate}/>} - {<DummyData name='endDate' value={this.props.data.endDate}/>}</h3>
				<h1 >{this.props.data.positionTitle} </h1>
				<h2>{this.props.data.companyName}</h2>
				{this.props.data.description.map(element=> {
					return <li key={element+'WorkPositionCubeDev'}> {element} </li>;
				})}
			</span>;
		}
	}

}
