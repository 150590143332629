import React from 'react';
import WebsiteHeader from './components/webSiteHeader.js';
import FlexBoxResume from '../../externalProjects/resume/js/FlexBoxResume';
import styles from  '../../sass/components/manjeetResume.scss';


const Resume = (props)=>{
	let data = props.props;
	return <div className='landing'>
		<WebsiteHeader changeLayout={props.changeLayout} layout={props.props.layout}/>
		<section className='manjeetResume'>
			<FlexBoxResume props={data}/>
		</section>
	</div>;
};

export default Resume;