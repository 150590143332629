import React from 'react';

let ribbonStyle={fill:'#001A2E',stroke:'#FF9800',strokeWidth:'10.30000019'};
export default class Ribbon extends React.Component {
	constructor(props){
		super(props);
	}

	render() {
		return (
			<svg
				width="100%"
				viewBox="0 0 943.05866 186.25881"
				id="svg8">

				<g id="layer1"
					transform="translate(64.069104,-55.67295)">
					<g id="g910"
						transform="translate(5.5224624,-124.2554)">
						<path
							id="shadowBox"
							transform="matrix(0.26458333,0,0,0.26458333,-61.999793,204.08285)"
							d="m 1757.4785,19.507812 c -440.9449,0 -1326.84764,84.283208 -1326.84764,84.283208 V 245.08398 H 19.462891 L 168.37891,395.38672 19.462891,550.46484 553.625,556.14062 l -3.10742,-4 H 553.625 V 395.99023 c 257.11436,-20.7075 859.2336,-66.09375 1199.8398,-66.09375 340.6069,0 942.7262,45.38629 1199.8399,66.09375 v 164.53125 h 3.1074 l -3.1074,4 534.1621,-5.67773 -148.916,-155.07813 148.916,-150.30273 h -411.168 V 103.79102 c 0,0 -877.8754,-84.283208 -1318.8203,-84.283208 z"
							style={{opacity:'1',fill:'#4d4d4d'}} />
						<path
							style={{fill:'#4d4d4d'}}
							d="m 33.505825,261.25397 h 50.974533 v 64.7618 H 33.505825 Z"
							id="leftDarkShadow"/>
						<path
							id="leftRibbon"
							d="m -56.850235,244.77347 39.400695,39.7676 -39.400695,41.03108 141.330595,1.50196 -32.542406,-41.9013 v -40.39934 z"
							style={ribbonStyle}/>
						<path
							transform="scale(-1,1)"
							style={{fill:'#4d4d4d'}}
							d="m -770.37006,263.47104 h 50.97454 v 64.7618 h -50.97454 z"
							id="rightDarkShadow" />
						<path
							style={ribbonStyle}
							d="m 860.72613,246.99054 -39.40069,39.7676 39.40069,41.03108 -141.33059,1.50196 32.54241,-41.9013 v -40.39934 z"
							id="rightRibbon" />
						<path
							id="middleRibbon"
							d="m 51.937954,207.38988 c 0,0 234.395236,-22.30003 351.061906,-22.30003 116.66666,0 348.93809,22.30003 348.93809,22.30003 v 80 c 0,0 -233.33333,-20.17622 -350,-20.17622 -116.66667,0 -349.999996,20.17622 -349.999996,20.17622 z"
							style={ribbonStyle} />
						<g aria-label="Front-End Engineer"
							transform="rotate(2.8490019,-704.62435,2806.2893)"
							style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
							id="text871">
							<path
								d="m 74.774901,190.21516 -13.808192,1.26286 1.366775,14.94446 -7.212866,0.65967 -3.289296,-35.96552 23.540621,-2.15296 0.508304,5.55786 -16.327754,1.49329 0.905911,9.90534 13.808192,-1.26286 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path914"/>
							<path
								d="m 94.435519,182.85546 -2.650383,0.15894 q -1.606077,0.14158 -2.585039,0.9002 -0.978962,0.75862 -1.415827,2.01728 l 1.592234,18.06218 -7.19028,0.63384 -2.356768,-26.73499 6.696103,-0.59028 0.66972,3.9251 q 0.753794,-2.18302 2.133952,-3.47503 1.40269,-1.31889 3.35469,-1.49097 0.543595,-0.0479 0.997067,0.0117 0.476003,0.0327 0.956363,0.1149 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path916"/>
							<path
								d="m 97.258781,189.57616 q -0.510392,-5.98099 2.50132,-10.07181 3.009599,-4.11552 8.916459,-4.61959 5.93156,-0.50617 9.61758,3.01309 3.68601,3.51925 4.19852,9.52496 l 0.0443,0.51902 q 0.51461,6.03042 -2.52182,10.12334 -3.01382,4.0661 -8.92067,4.57016 -5.93157,0.50618 -9.61548,-2.98836 -3.661298,-3.52137 -4.175908,-9.5518 z m 7.236319,-0.0947 q 0.31214,3.6578 1.78712,5.77246 1.47288,2.08996 4.33981,1.84531 2.79278,-0.23833 3.88813,-2.57234 1.12008,-2.33611 0.81005,-5.9692 l -0.0443,-0.51901 q -0.30371,-3.55894 -1.80552,-5.69621 -1.50181,-2.13728 -4.31931,-1.89684 -2.79278,0.23832 -3.90863,2.62387 -1.09325,2.35872 -0.79165,5.89295 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path918"/>
							<path
								d="m 132.48306,173.38346 0.63512,3.80559 q 1.16699,-2.16122 3.02691,-3.45833 1.85993,-1.29712 4.28268,-1.49548 4.0544,-0.33196 6.54338,2.10235 2.48695,2.40959 2.94845,8.0462 l 1.34198,16.39066 -7.24353,0.59307 -1.33996,-16.36594 q -0.2348,-2.86775 -1.34556,-3.97142 -1.08806,-1.13041 -3.09053,-0.96646 -1.60693,0.13157 -2.79243,0.85082 -1.1628,0.69251 -1.86686,1.82033 l 1.58487,19.3573 -7.21881,0.59104 -2.19008,-26.74917 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path920"/>
							<path
								d="m 162.73734,164.35201 0.51751,6.55284 4.52517,-0.35738 0.40034,5.06918 -4.52517,0.35738 1.07603,13.62495 q 0.12303,1.55785 0.81868,2.17472 0.72038,0.61492 1.78367,0.53095 0.56873,-0.0449 0.9852,-0.12757 0.41451,-0.10738 0.9468,-0.29871 l 1.00552,5.17067 q -1.05872,0.45684 -2.08361,0.71196 -0.99822,0.27789 -2.20987,0.37358 -3.73389,0.29488 -5.92364,-1.59738 -2.16504,-1.89422 -2.51655,-6.3452 l -1.07799,-13.64969 -3.90697,0.30855 -0.40034,-5.06917 3.90697,-0.30856 -0.51751,-6.55283 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path922"/>
							<path
								d="m 188.76532,183.16742 -14.56759,1.1119 -0.42475,-5.56487 14.56759,-1.1119 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path924"/>
							<path
								d="m 216.35736,177.90796 -13.35929,0.9709 0.74796,10.29161 15.88271,-1.1543 0.40275,5.54163 -23.10663,1.67931 -2.61786,-36.02062 23.05716,-1.67571 0.40454,5.56637 -15.83324,1.15071 0.65806,9.05463 13.3593,-0.97091 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path926"/>
							<path
								d="m 229.37461,166.01045 0.58264,3.81398 q 1.19664,-2.14494 3.07426,-3.41631 1.87762,-1.27136 4.30288,-1.43633 4.05859,-0.27607 6.51379,2.1923 2.45352,2.44363 2.83732,8.08606 l 1.11604,16.40759 -7.25101,0.49322 -1.11437,-16.38285 q -0.19526,-2.87071 -1.29071,-3.98958 -1.07238,-1.14529 -3.07693,-1.00894 -1.60859,0.10941 -2.80388,0.81227 -1.17223,0.67642 -1.89176,1.79443 l 1.31804,19.3773 -7.22627,0.49153 -1.82136,-26.7768 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path928"/>
							<path
								d="m 251.26349,178.20156 q -0.39626,-6.33762 2.08392,-10.39463 2.50494,-4.05857 7.48096,-4.36969 2.00527,-0.12538 3.6686,0.64048 1.66334,0.76585 2.97702,2.29917 l -0.9179,-14.68051 7.2536,-0.45353 2.41472,38.61989 -6.16433,0.38543 -0.82478,-3.2539 q -1.14648,1.93567 -2.81751,3.03428 -1.67258,1.07385 -3.90065,1.21316 -4.92651,0.30803 -7.90046,-3.13458 -2.94919,-3.44416 -3.32068,-9.38568 z m 7.23661,0.0694 q 0.2198,3.5154 1.53503,5.47112 1.31368,1.93097 3.98737,1.76379 1.55965,-0.0975 2.6845,-0.78917 1.1233,-0.71642 1.79014,-1.97592 l -0.71357,-11.41267 q -0.82164,-1.21614 -2.02853,-1.83656 -1.18368,-0.64674 -2.71857,-0.55077 -2.64893,0.16563 -3.7397,2.59488 -1.06601,2.4277 -0.82918,6.21542 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path930"/>
							<path
								d="m 314.8219,171.83619 -13.37613,0.7018 0.54065,10.30457 15.90273,-0.83436 0.29112,5.54862 -23.13576,1.21385 -1.89226,-36.06601 23.08622,-1.21126 0.29241,5.57339 -15.85319,0.83176 0.47566,9.06605 13.37614,-0.7018 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path932"/>
							<path
								d="m 328.2001,160.23455 0.48767,3.82728 q 1.24959,-2.11454 3.15822,-3.33886 1.90864,-1.22431 4.33724,-1.32895 4.0642,-0.17512 6.4573,2.3535 2.39204,2.50384 2.6355,8.15407 l 0.70794,16.43026 -7.26103,0.31286 -0.70688,-16.40548 q -0.12387,-2.87467 -1.19117,-4.02042 -1.04359,-1.17159 -3.05091,-1.0851 -1.61081,0.0694 -2.8232,0.74234 -1.18867,0.64709 -1.93577,1.74687 l 0.83608,19.40406 -7.23625,0.3118 -1.15536,-26.81379 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path934" />
							<path
								d="m 349.92698,172.96037 q -0.19121,-6.34713 2.4436,-10.3226 2.63481,-3.97548 7.61829,-4.12561 2.25621,-0.068 3.99758,0.89703 1.74137,0.965 3.01301,2.8127 l 0.46718,-3.43867 6.22315,-0.18748 0.80441,26.70254 q 0.1576,5.23141 -3.178,8.18573 -3.33486,2.97911 -9.45884,3.1636 -2.03306,0.0612 -4.33123,-0.44029 -2.29743,-0.47674 -4.26045,-1.38543 l 1.05206,-5.44156 q 1.68506,0.74334 3.45734,1.08701 1.79783,0.36771 3.85568,0.30572 2.97521,-0.0896 4.3263,-1.37113 1.37588,-1.28225 1.29671,-3.91035 l -0.0732,-2.42976 q -1.14453,1.54825 -2.7322,2.39019 -1.58767,0.84194 -3.67032,0.90468 -4.9339,0.14863 -7.79502,-3.3883 -2.86112,-3.53694 -3.04037,-9.48736 z m 7.25537,0.30256 q 0.10606,3.52067 1.3326,5.51863 1.25058,1.97242 3.92827,1.89175 1.66116,-0.05 2.78374,-0.67944 1.14662,-0.65494 1.80573,-1.84115 l -0.3503,-11.62812 q -0.73156,-1.21876 -1.91702,-1.85308 -1.16066,-0.63506 -2.77224,-0.58652 -2.65289,0.0799 -3.79682,2.4719 -1.14392,2.39197 -1.02964,6.18537 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path936"/>
							<path
								d="m 387.66856,185.20048 -7.24193,0.12274 -0.45481,-26.83482 7.24193,-0.12274 z m -0.56368,-33.25831 -7.24192,0.12274 -0.0921,-5.43145 7.24193,-0.12274 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path938"/>
							<path
								d="m 400.31676,158.2436 0.31449,3.84539 q 1.34372,-2.05601 3.30565,-3.19296 1.96193,-1.13695 4.39278,-1.13191 4.06796,0.008 6.34454,2.64246 2.27662,2.60922 2.26489,8.26468 l -0.0341,16.44547 -7.26775,-0.0151 0.034,-16.42067 q 0.006,-2.87734 -1.00855,-4.07007 -0.98966,-1.21748 -2.99884,-1.22165 -1.6123,-0.003 -2.85382,0.6142 -1.21666,0.59279 -2.01262,1.65774 l -0.0403,19.42203 -7.24296,-0.015 0.0557,-26.83862 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path940"/>
							<path
								d="m 433.93056,186.03773 q -6.00097,-0.14565 -9.28434,-3.89751 -3.28277,-3.77666 -3.14194,-9.57925 l 0.0241,-0.9919 q 0.14625,-6.02576 3.51279,-9.78991 3.36654,-3.76415 9.09413,-3.60033 5.40583,0.1312 8.20168,3.52386 2.82125,3.36847 2.68524,8.97268 l -0.0909,3.7444 -15.99431,-0.38818 -0.078,0.14698 q 0.10677,2.75672 1.55309,4.50385 1.47111,1.74773 4.422,1.81935 2.43015,0.059 4.1284,-0.39604 1.72306,-0.45443 3.55794,-1.42718 l 1.85067,4.51107 q -1.64373,1.27514 -4.41791,2.10104 -2.77417,0.82591 -6.02263,0.74707 z m 0.0693,-22.27949 q -2.18217,-0.053 -3.40916,1.43079 -1.20158,1.45956 -1.50974,3.93328 l 0.0466,0.12519 8.82787,0.21425 0.0132,-0.54554 q 0.0572,-2.35575 -0.87742,-3.71828 -0.934,-1.38733 -3.09137,-1.43969 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path942" />
							<path
								d="m 460.4945,186.90123 q -5.99834,-0.22969 -9.22883,-4.02718 -3.22955,-3.82227 -3.00745,-9.62231 l 0.038,-0.99147 q 0.23064,-6.02312 3.64958,-9.73975 3.41894,-3.71662 9.14367,-3.47259 5.40346,0.20692 8.15152,3.63841 2.77379,3.40765 2.55928,9.00941 l -0.14332,3.74276 -15.9873,-0.61219 -0.0801,0.14587 q 0.0682,2.75795 1.48986,4.52517 1.44648,1.76817 4.39608,1.88111 2.42908,0.093 4.13354,-0.33817 1.72926,-0.43024 3.57759,-1.3772 l 1.78729,4.53655 q -1.66143,1.252 -4.4469,2.03896 -2.78547,0.78696 -6.03251,0.66262 z m 0.38138,-22.27633 q -2.18122,-0.0835 -3.42887,1.3829 -1.22191,1.44258 -1.56469,3.91174 l 0.0448,0.12583 8.824,0.33789 0.0209,-0.5453 q 0.0902,-2.35472 -0.82525,-3.73021 -0.91447,-1.40027 -3.0709,-1.48285 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path944"/>
							<path
								d="m 490.5236,167.07488 -2.64768,-0.19895 q -1.61053,-0.0757 -2.68262,0.54437 -1.07209,0.62007 -1.67426,1.80854 l -0.85137,18.11222 -7.21021,-0.33891 1.26017,-26.80907 6.71466,0.31562 0.13576,3.97951 q 1.04053,-2.06181 2.58191,-3.15647 1.56732,-1.11826 3.52473,-1.02626 0.5451,0.0256 0.98644,0.1457 0.46727,0.0965 0.93222,0.24248 z"
								style={{fill:'#e5e5e5',strokeWidth:'0.26458332'}}
								id="path946"/>
						</g>
					</g>
				</g>
			</svg>
		);
	}
}
