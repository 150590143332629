import React from 'react';
import WorkPosition from './JobDescription/workPosition';
// import styles from '../css/WorkPositionList.css';
import Section from './section';
import AdditionalSkills from './additionalSkills';


export let dummyPosition1 = {
	startDate: '01/2013',
	endDate: '04/2016',
	location: 'Vaughn, ON',
	positionTitle: 'Lead Minion Manager',
	companyName:'ZooMinion Inc.',
	workTitle: 'Tasks/Achievements',
	description: [
		'Directed the minions to do good for a change',
		'learned the potato song',
		'awww they are cute. I will not spend money watching their movie in theatres',
		'I am "hopeful" that you understand that this section needs to be filled with your information'
	]
};

let dummyPositionList=[dummyPosition1,dummyPosition1,dummyPosition1];
export default class WorkPositionList extends React.Component{
	constructor(props){
		super(props);
		this.showWorkPositionList = this.showWorkPositionList.bind(this);
		this.showDummyData = this.showDummyData.bind(this);
	}

	showWorkPositionList(){
		if(this.props.layout !== 'professional'){
			return  <div className='WorkPositionListFun'>
				<Section name='Work Experiences' layout={this.props.layout}/>
				<div id='workPositionFun' className='workPositionFun'>
					<input id="front" name="transforms" type="radio" value="front"/>
					{((this.props.jobList).length > 0)?
						<label htmlFor="front"> Job1</label>:
						<label htmlFor="front" style={{display:'none'}}> Job1</label>
					}


					<input id="left" name="transforms" type="radio" value="left"/>
					{((this.props.jobList).length >1)?
						<label htmlFor="left">Job2</label>:
						''
					}


					<input id="right" name="transforms" type="radio" value="right"/>
					{((this.props.jobList).length >2)?
						<label htmlFor="right">Job3</label>:
						''
					}


					<input id="top" name="transforms" type="radio" value="top"/>
					{((this.props.jobList).length >3)?
						<label htmlFor="top">Job4</label>:
						''
					}


					<input id="bottom" name="transforms" type="radio" value="bottom"/>
					{((this.props.jobList).length >4)?
						<label htmlFor="bottom">Job5</label>:
						''
					}


					<input id="back" name="transforms" type="radio" value="back"/>
					{((this.props.jobList).length >5)?
						<label htmlFor="back">Job6</label>:
						''
					}


					<div className="container">
						<div className="cube">
							<div className="front">
								{((this.props.jobList).length > 0) ? <WorkPosition data={this.props.jobList[0]}  layout={this.props.layout}/>
									: ''}
							</div>
							<div className="left">
								{((this.props.jobList).length > 1) ? <WorkPosition data={this.props.jobList[1]}  layout={this.props.layout}/>
									: ''}
							</div>
							<div className="right">
								{((this.props.jobList).length > 2) ? <WorkPosition data={this.props.jobList[2]}  layout={this.props.layout}/>
									: ''}
							</div>
							<div className="top">
								{((this.props.jobList).length > 3) ? <WorkPosition data={this.props.jobList[3]}  layout={this.props.layout}/>
									: ''}
							</div>
							<div className="bottom">
								{((this.props.jobList).length > 4) ? <WorkPosition data={this.props.jobList[4]}  layout={this.props.layout}/>
									: ''}
							</div>
							<div className="back">
								{((this.props.jobList).length > 5) ? <WorkPosition data={this.props.jobList[5]}  layout={this.props.layout}/>
									: ''}
							</div>
						</div>
					</div>
				</div>
			</div>;
		} else {
			return <div className='WorkPositionList'>
				<Section name='Work Experiences' layout={this.props.layout}/>
				{this.props.jobList.map((element, index) => {
					return <WorkPosition data={element} index={index} key={index} layout={this.props.layout}/>;
				})}
			</div>;
		}

	}



	showDummyData(){
		return <div >
			<Section name='Work Experiences' layout={this.props.layout} />
			{dummyPositionList.map((element, index) => {
				return <WorkPosition data={element} index={index} key={index} layout={this.props.layout}/>;
			})}
		</div>;
	}
	render() {
		return <div className='dummyContainer'>
			{(typeof this.props.jobList === 'undefined')? this.showDummyData()
				: this.showWorkPositionList()}</div>;

	}
}
