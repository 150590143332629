import React, {useEffect} from 'react';
import {HashRouter} from 'react-router-dom';
import WebsiteRoute from './route';
function addFontLink() {
	// Check if the link element already exists
	if (!document.getElementById('documentFontLink')) {
	  // Create a new link element
	  const link = document.createElement('link');
	  link.id = 'documentFontLink';
	  link.href = 'https://fonts.googleapis.com/css?family=Bungee|Londrina+Outline|Roboto';
	  link.rel = 'stylesheet';
  
	  // Append the link element to the head
	  document.head.appendChild(link);
	}
  }
const App = () =>{
	useEffect(()=>{
		addFontLink();
	}, [])
	return <HashRouter>
		<WebsiteRoute />
	</HashRouter>;
};

export default App;