import React from 'react';
import styles from '../sass/components/section.scss';

export default class Section extends React.Component{
	render() {
		if(this.props.layout ==='professional'){
			if(this.props.name==='Awards'){
				return <h1 className='sectionHeaderAwards'>
					{this.props.name}
				</h1>;
			} else {
				return <h1 className='sectionHeader'>
					{this.props.name}
				</h1>;
			}
		}else{
			return <h1 className='sectionHeaderFun'>
				{this.props.name}
			</h1>;
		}

	}
}
