import {updateFont} from './updateFont';
let initialLayout = {
	layout:'professional',

};

const LayoutReducer = (state = initialLayout, action) => {
	switch(action.type){
	case 'CHANGE LAYOUT':{
		let newObj;
		if(action.layout === 'professional'){
			newObj= Object.assign({}, {layout:'personal', font:'Londrina+Outline|Shadows+Into+Light+Two'});
		} else {
			newObj=  Object.assign({}, {layout:'professional', font:'Roboto'});
		}
		return newObj;
	}
	default:
		return state;
	}


};

// module.exports = LayoutReducer;

export default LayoutReducer;