import { createStore, combineReducers,applyMiddleware } from 'redux';
import LayoutReducer from './layoutReducer';
import {thunk} from 'redux-thunk';

const Reducers = combineReducers({
	layout:LayoutReducer
});

const store = createStore(
	Reducers,
	applyMiddleware(thunk));
export default store;
