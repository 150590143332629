import React from 'react';

let shapeObjOriginal = {opacity:'1',fill:'white',stroke:'white',strokeWidth:'3'};
let shapeObj = {opacity:'1',fill:'white',stroke:'white',strokeWidth:'3'};
let fontObj = {fill:'#001A2E',fillOpacity:'1',stroke:'none',fontFamily:'Roboto',fontWeight:'500',fontSize:'40px',lineHeight:'1.25',letterSpacing:'0px',wordSpacing:'0px',fontStretch:'normal',fontVariant:'normal'}
class Strengths extends React.Component {
	constructor(props){
		super(props);

	}
	render() {

		console.log(this.props.fun);
		return (
			<svg
				width="100%"
				viewBox="0 0 297 210"
				version="1.1"
				id="svg8">

			<g transform="translate(0,-87)">
			  <path
			     style={(this.props.fun === false)?shapeObjOriginal : Object.assign({}, shapeObj, {fill:'#C9143B'})}
			     d="m 82.844221,183.42893 21.925779,-7.9375 21.9258,7.9375 c 0.96102,-11.4953 8.08163,-21.43526 18.38699,-25.66716 l 1.76999,-24.06312 -13.56409,-19.95437 c -29.20713,9.87066 -49.366551,37.71915 -50.444469,69.68465 z"
			     id="path4614" />
			  <path
			     style={(this.props.fun === false)?shapeObjOriginal:Object.assign({}, shapeObj, {fill:'#001A2E'})}
			     d="m 82.880911,188.7082 c 1.096245,32.07168 21.397319,59.97668 50.742639,69.7487 l -1.77463,-24.04501 13.55944,-19.93628 c -10.46057,-4.14608 -17.72712,-14.15385 -18.70947,-25.76743 l -21.90898,-7.93748 z"
			     id="path4612" />
			  <path
			     style={(this.props.fun === false)?shapeObjOriginal:Object.assign({}, shapeObj, {fill:'#FF9800'})}
			     d="m 138.36486,112.29011 13.5553,19.92129 -1.77877,24.03004 c 0.46404,-0.0975 0.93009,-0.18316 1.39773,-0.25693 0.46762,-0.0738 0.93685,-0.13576 1.40723,-0.18582 0.94076,-0.10012 1.88619,-0.15263 2.83295,-0.15722 3.43872,2.5e-4 6.82225,0.63245 10.00407,1.84095 3.18182,1.20848 6.16192,2.99326 8.79374,5.29867 l 21.44374,-10.21842 10.21841,-21.44373 c -13.58269,-13.54385 -31.65721,-21.10825 -50.45996,-21.11809 -5.87207,0.031 -11.7191,0.79961 -17.41444,2.28926 z"
			     id="path4610" />
			  <path
			     style={(this.props.fun === false)?shapeObjOriginal:Object.assign({}, shapeObj, {fill:'#FF9800'})}
			     d="m 138.69301,259.93692 c 5.59224,1.43234 11.32752,2.17059 17.08628,2.19936 25.72741,-0.035 49.53898,-14.16952 62.68197,-37.20809 l -23.12573,-4.18622 -15.18821,-17.93438 c -5.39928,8.53729 -14.55359,13.67946 -24.36802,13.68805 -0.88998,-0.004 -1.77886,-0.0497 -2.66389,-0.13779 -0.88502,-0.0881 -1.76617,-0.21833 -2.6407,-0.39035 l -13.5579,19.93034 z"
			     id="path4608" />
			  <path
			     style={(this.props.fun === false)?shapeObjOriginal:Object.assign({}, shapeObj, {fill:'#C9143B'})}
			     d="m 178.20739,166.61755 c 4.3729,5.45913 6.77245,12.34108 6.78202,19.45102 -0.002,2.07293 -0.20683,4.13476 -0.60899,6.154 -0.40216,2.01923 -1.00138,3.99589 -1.7914,5.89848 l 15.22775,17.95713 23.16527,4.20896 c 5.13812,-10.61292 7.81717,-22.33243 7.82226,-34.21857 -0.0314,-18.88278 -6.8039,-37.07841 -19.00039,-51.04806 l -10.1856,21.41118 z"
			     id="path875" />
			  <flowRoot
			     id="flowRoot4670"
			     style={fontObj}><flowRegion
			       id="flowRegion4672"><rect
			         id="rect4674"
			         width="133.6106"
			         height="102.37695"
			         x="161.37384"
			         y="490.90796" /></flowRegion><flowPara
			       id="flowPara4676"></flowPara></flowRoot>    <g
			     aria-label="Responsive Web Design"
			     transform="matrix(0.26458333,0,0,0.26458333,75.935417,21.11875)"
			     style={(this.props.fun === false)?fontObj:Object.assign({}, fontObj, {fill:'white'})}
			     id="flowRoot4686">
			    <path
			       d="m 79.945312,485.4668 v 8.5677 h -1.5625 v -18.94531 h 6.315104 q 3.046875,0 4.67448,1.34115 Q 91,477.75846 91,480.32357 q 0,1.51041 -0.872396,2.70833 -0.859375,1.1849 -2.447917,1.73177 1.757813,0.44271 2.526042,1.58854 0.78125,1.13282 0.78125,2.85157 v 1.78385 q 0,0.88542 0.208333,1.58854 0.208333,0.70313 0.690104,1.14583 v 0.3125 h -1.588541 q -0.481771,-0.46875 -0.677084,-1.3151 -0.195312,-0.84635 -0.195312,-1.75781 v -1.73177 q 0,-1.74479 -1.132813,-2.7474 -1.119791,-1.01562 -3.13802,-1.01562 z m 0,-1.32813 h 4.570313 q 2.565104,0 3.75,-1.02864 1.184896,-1.04167 1.184896,-2.79948 0,-1.875 -1.184896,-2.87761 -1.171875,-1.01562 -3.567709,-1.01562 h -4.752604 z"
			       style={{}}
			       id="path4815" />
			    <path
			       d="m 100.80469,494.30794 q -2.734379,0 -4.440108,-1.92708 -1.705729,-1.94011 -1.705729,-4.97396 v -0.71615 q 0,-3.04687 1.71875,-5.02604 1.731771,-1.97916 4.283857,-1.97916 2.5651,0 3.95833,1.65364 1.40625,1.64063 1.40625,4.42709 v 1.21093 h -9.804687 v 0.42969 q 0,2.40885 1.210937,3.9974 1.223959,1.58854 3.3724,1.58854 1.28906,0 2.36979,-0.41667 1.08073,-0.42969 1.79687,-1.14583 l 0.66407,1.06771 q -0.74219,0.78125 -1.95313,1.30208 -1.19792,0.50781 -2.8776,0.50781 z m -0.14323,-13.29427 q -1.796878,0 -2.955732,1.28906 -1.158854,1.28907 -1.354167,3.26823 l 0.03906,0.0781 h 8.072916 v -0.32552 q 0,-1.875 -0.98958,-3.08594 -0.97657,-1.22396 -2.8125,-1.22396 z"
			       style={{}}
			       id="path4817" />
			    <path
			       d="m 117.64062,490.42773 q 0,-0.98958 -0.82031,-1.71875 -0.80729,-0.74218 -2.92969,-1.21093 -2.5,-0.52084 -3.72395,-1.41927 -1.21094,-0.91146 -1.21094,-2.53907 0,-1.61458 1.35416,-2.73437 1.36719,-1.11979 3.60678,-1.11979 2.35677,0 3.76302,1.19791 1.40625,1.1849 1.34114,2.92969 l -0.026,0.0781 h -1.44531 q 0,-1.15886 -1.00261,-2.01823 -0.98958,-0.85938 -2.6302,-0.85938 -1.69271,0 -2.55209,0.74219 -0.85937,0.74219 -0.85937,1.73177 0,0.96354 0.72916,1.60156 0.74219,0.625 2.94271,1.10677 2.47396,0.54688 3.73698,1.52344 1.27604,0.97656 1.27604,2.63021 0,1.75781 -1.43229,2.86458 -1.41927,1.09375 -3.76302,1.09375 -2.5651,0 -4.03646,-1.22396 -1.45833,-1.22395 -1.38021,-2.91666 l 0.026,-0.0781 h 1.43229 q 0.0911,1.52344 1.23698,2.21354 1.15885,0.69011 2.72135,0.69011 1.69271,0 2.66927,-0.75521 0.97656,-0.75521 0.97656,-1.8099 z"
			       style={{}}
			       id="path4819" />
			    <path
			       d="m 134.13802,487.38086 q 0,3.16406 -1.43229,5.05208 -1.43229,1.875 -3.94531,1.875 -1.47136,0 -2.59115,-0.55989 -1.10677,-0.5599 -1.8099,-1.58855 v 7.29167 h -1.5625 v -19.50521 h 1.19792 l 0.28646,2.20052 q 0.70312,-1.17187 1.82292,-1.80989 1.11979,-0.65104 2.6302,-0.65104 2.53907,0 3.97136,2.03125 1.43229,2.01823 1.43229,5.39062 z m -1.5625,-0.27344 q 0,-2.68229 -1.06771,-4.375 -1.06771,-1.69271 -3.125,-1.69271 -1.54948,0 -2.53906,0.74219 -0.97656,0.74219 -1.48438,1.92708 v 6.82292 q 0.54688,1.14583 1.54948,1.8099 1.00261,0.65104 2.5,0.65104 2.04427,0 3.09896,-1.53646 1.06771,-1.53646 1.06771,-4.07552 z"
			       style={{}}
			       id="path4821" />
			    <path
			       d="m 136.76823,486.7819 q 0,-3.11198 1.70573,-5.10417 1.70573,-1.99218 4.51823,-1.99218 2.82552,0 4.53125,1.99218 1.70573,1.99219 1.70573,5.10417 v 0.42969 q 0,3.125 -1.70573,5.11719 -1.69271,1.97916 -4.50521,1.97916 -2.83854,0 -4.54427,-1.97916 -1.70573,-1.99219 -1.70573,-5.11719 z m 1.5625,0.42969 q 0,2.44791 1.23698,4.11458 1.23698,1.66667 3.45052,1.66667 2.17448,0 3.41146,-1.66667 1.25,-1.66667 1.25,-4.11458 v -0.42969 q 0,-2.40885 -1.25,-4.08854 -1.25,-1.67969 -3.4375,-1.67969 -2.1875,0 -3.42448,1.67969 -1.23698,1.67969 -1.23698,4.08854 z"
			       style={{}}
			       id="path4823" />
			    <path
			       d="m 153.86458,479.94596 0.14323,2.44792 q 0.6901,-1.28906 1.83593,-1.99219 1.14584,-0.71614 2.72136,-0.71614 2.29167,0 3.48958,1.40625 1.19792,1.39323 1.19792,4.36198 v 8.58072 h -1.5625 v -8.5677 q 0,-2.42188 -0.91146,-3.42448 -0.89844,-1.00261 -2.5651,-1.00261 -1.66667,0 -2.69532,0.82032 -1.01562,0.82031 -1.47135,2.20052 v 9.97395 h -1.5625 v -14.08854 z"
			       style={{}}
			       id="path4825" />
			    <path
			       d="m 175.76562,490.42773 q 0,-0.98958 -0.82031,-1.71875 -0.80729,-0.74218 -2.92969,-1.21093 -2.5,-0.52084 -3.72396,-1.41927 -1.21094,-0.91146 -1.21094,-2.53907 0,-1.61458 1.35417,-2.73437 1.36719,-1.11979 3.60677,-1.11979 2.35677,0 3.76302,1.19791 1.40625,1.1849 1.34115,2.92969 l -0.026,0.0781 h -1.44532 q 0,-1.15886 -1.0026,-2.01823 -0.98958,-0.85938 -2.63021,-0.85938 -1.69271,0 -2.55208,0.74219 -0.85938,0.74219 -0.85938,1.73177 0,0.96354 0.72917,1.60156 0.74219,0.625 2.94271,1.10677 2.47396,0.54688 3.73698,1.52344 1.27604,0.97656 1.27604,2.63021 0,1.75781 -1.43229,2.86458 -1.41927,1.09375 -3.76302,1.09375 -2.56511,0 -4.03646,-1.22396 -1.45834,-1.22395 -1.38021,-2.91666 l 0.026,-0.0781 h 1.43229 q 0.0911,1.52344 1.23698,2.21354 1.15886,0.69011 2.72136,0.69011 1.6927,0 2.66927,-0.75521 0.97656,-0.75521 0.97656,-1.8099 z"
			       style={{}}
			       id="path4827" />
			    <path
			       d="m 182.66666,494.0345 h -1.54948 v -14.08854 h 1.54948 z m 0,-18.12499 h -1.54948 v -2.1875 h 1.54948 z"
			       style={{}}
			       id="path4829" />
			    <path
			       d="m 190.97396,490.50586 0.45573,1.65364 h 0.0781 l 0.49479,-1.65364 3.82813,-10.5599 h 1.61458 l -5.35156,14.08854 h -1.25 l -5.36458,-14.08854 h 1.61458 z"
			       style={{}}
			       id="path4831" />
			    <path
			       d="m 205.23177,494.30794 q -2.73438,0 -4.44011,-1.92708 -1.70573,-1.94011 -1.70573,-4.97396 v -0.71615 q 0,-3.04687 1.71875,-5.02604 1.73178,-1.97916 4.28386,-1.97916 2.5651,0 3.95833,1.65364 1.40625,1.64063 1.40625,4.42709 v 1.21093 h -9.80469 v 0.42969 q 0,2.40885 1.21094,3.9974 1.22396,1.58854 3.3724,1.58854 1.28906,0 2.36979,-0.41667 1.08073,-0.42969 1.79687,-1.14583 l 0.66407,1.06771 q -0.74219,0.78125 -1.95313,1.30208 -1.19791,0.50781 -2.8776,0.50781 z m -0.14323,-13.29427 q -1.79688,0 -2.95573,1.28906 -1.15885,1.28907 -1.35417,3.26823 l 0.0391,0.0781 h 8.07291 v -0.32552 q 0,-1.875 -0.98958,-3.08594 -0.97656,-1.22396 -2.8125,-1.22396 z"
			       style={{}}
			       id="path4833" />
			    <path
			       d="m 81.729167,521.92514 0.585937,3.0599 h 0.07813 l 0.664062,-3.0599 3.958334,-13.51562 h 1.575521 l 3.971354,13.51562 0.63802,3.07292 h 0.07813 l 0.625,-3.07292 3.346354,-13.51562 h 1.692709 l -4.856771,18.95833 h -1.484375 l -4.283854,-14.54427 -0.46875,-2.04427 h -0.07813 l -0.442708,2.04427 -4.335938,14.54427 h -1.484375 l -4.85677,-18.95833 h 1.692708 z"
			       style={{}}
			       id="path4835" />
			    <path
			       d="m 106.58594,527.64129 q -2.73438,0 -4.44011,-1.92709 -1.70573,-1.9401 -1.70573,-4.97396 v -0.71614 q 0,-3.04688 1.71875,-5.02604 1.73177,-1.97917 4.28386,-1.97917 2.5651,0 3.95833,1.65365 1.40625,1.64062 1.40625,4.42708 v 1.21094 h -9.80469 v 0.42968 q 0,2.40886 1.21094,3.9974 1.22396,1.58854 3.3724,1.58854 1.28906,0 2.36979,-0.41667 1.08073,-0.42968 1.79687,-1.14583 l 0.66407,1.06771 q -0.74219,0.78125 -1.95313,1.30208 -1.19792,0.50782 -2.8776,0.50782 z m -0.14323,-13.29427 q -1.79688,0 -2.95573,1.28906 -1.15886,1.28906 -1.35417,3.26823 l 0.0391,0.0781 h 8.07292 v -0.32552 q 0,-1.875 -0.98958,-3.08594 -0.97657,-1.22395 -2.8125,-1.22395 z"
			       style={{}}
			       id="path4837" />
			    <path
			       d="m 126.33854,520.7142 q 0,3.16406 -1.43229,5.05209 -1.43229,1.875 -3.94531,1.875 -1.53646,0 -2.66927,-0.61198 -1.1198,-0.625 -1.82292,-1.77084 l -0.26042,2.10938 h -1.22396 v -20.3125 h 1.5625 v 8.38542 q 0.70313,-1.15886 1.79688,-1.78386 1.09375,-0.63802 2.59114,-0.63802 2.53907,0 3.97136,2.03125 1.43229,2.01823 1.43229,5.39062 z m -1.57552,-0.27344 q 0,-2.69531 -1.04167,-4.37499 -1.04166,-1.69271 -3.08593,-1.69271 -1.61459,0 -2.60417,0.79427 -0.98958,0.78125 -1.48438,2.03125 v 6.44531 q 0.52084,1.22396 1.53646,1.9401 1.02865,0.70313 2.57813,0.70313 2.04427,0 3.07291,-1.51042 1.02865,-1.51041 1.02865,-4.0625 z"
			       style={{}}
			       id="path4839" />
			    <path
			       d="m 136.70312,527.36785 v -18.95833 h 5.78125 q 3.47657,0 5.74219,2.3177 2.27865,2.31771 2.27865,5.95052 v 2.4349 q 0,3.63281 -2.27865,5.95052 -2.26562,2.30469 -5.74219,2.30469 z m 1.5625,-17.63021 v 16.3151 h 4.21875 q 2.8125,0 4.63542,-1.96614 1.82292,-1.96615 1.82292,-4.97396 v -2.47396 q 0,-2.96875 -1.83594,-4.93489 -1.82292,-1.96615 -4.6224,-1.96615 z"
			       style={{}}
			       id="path4841" />
			    <path
			       d="m 159.65885,527.64129 q -2.73437,0 -4.4401,-1.92709 -1.70573,-1.9401 -1.70573,-4.97396 v -0.71614 q 0,-3.04688 1.71875,-5.02604 1.73177,-1.97917 4.28385,-1.97917 2.56511,0 3.95834,1.65365 1.40625,1.64062 1.40625,4.42708 v 1.21094 h -9.80469 v 0.42968 q 0,2.40886 1.21094,3.9974 1.22396,1.58854 3.37239,1.58854 1.28907,0 2.3698,-0.41667 1.08072,-0.42968 1.79687,-1.14583 l 0.66406,1.06771 q -0.74218,0.78125 -1.95312,1.30208 -1.19792,0.50782 -2.87761,0.50782 z m -0.14323,-13.29427 q -1.79687,0 -2.95572,1.28906 -1.15886,1.28906 -1.35417,3.26823 l 0.0391,0.0781 h 8.07292 v -0.32552 q 0,-1.875 -0.98959,-3.08594 -0.97656,-1.22395 -2.8125,-1.22395 z"
			       style={{}}
			       id="path4843" />
			    <path
			       d="m 176.49479,523.76108 q 0,-0.98959 -0.82031,-1.71875 -0.80729,-0.74219 -2.92969,-1.21094 -2.5,-0.52083 -3.72396,-1.41927 -1.21093,-0.91146 -1.21093,-2.53906 0,-1.61459 1.35416,-2.73438 1.36719,-1.11979 3.60677,-1.11979 2.35677,0 3.76302,1.19792 1.40625,1.18489 1.34115,2.92968 l -0.026,0.0781 h -1.44531 q 0,-1.15885 -1.00261,-2.01823 -0.98958,-0.85937 -2.63021,-0.85937 -1.69271,0 -2.55208,0.74218 -0.85938,0.74219 -0.85938,1.73177 0,0.96355 0.72917,1.60157 0.74219,0.625 2.94271,1.10677 2.47396,0.54687 3.73698,1.52343 1.27604,0.97657 1.27604,2.63021 0,1.75781 -1.43229,2.86459 -1.41927,1.09375 -3.76302,1.09375 -2.56511,0 -4.03646,-1.22396 -1.45833,-1.22396 -1.38021,-2.91667 l 0.026,-0.0781 h 1.43229 q 0.0911,1.52343 1.23698,2.21354 1.15886,0.6901 2.72136,0.6901 1.69271,0 2.66927,-0.75521 0.97656,-0.75521 0.97656,-1.80989 z"
			       style={{}}
			       id="path4845" />
			    <path
			       d="m 183.39583,527.36785 h -1.54948 v -14.08854 h 1.54948 z m 0,-18.125 h -1.54948 v -2.1875 h 1.54948 z"
			       style={{}}
			       id="path4847" />
			    <path
			       d="m 187.13281,520.44076 q 0,-3.37239 1.41927,-5.39062 1.43229,-2.03125 3.98438,-2.03125 1.49739,0 2.60416,0.65104 1.10677,0.63802 1.82292,1.82292 l 0.22135,-2.21354 h 1.26302 v 14.16666 q 0,2.69532 -1.48437,4.15365 -1.48438,1.45833 -4.20573,1.45833 -1.01563,0 -2.17448,-0.24739 -1.14583,-0.2474 -2.00521,-0.67709 l 0.29948,-1.3151 q 0.84636,0.41667 1.79688,0.625 0.96354,0.22135 2.05729,0.22135 2.08333,0 3.11198,-1.06771 1.02864,-1.0677 1.02864,-3.15104 v -1.99218 q -0.71614,1.05468 -1.82291,1.6276 -1.09375,0.5599 -2.53907,0.5599 -2.52604,0 -3.95833,-1.88803 -1.41927,-1.88802 -1.41927,-5.03906 z m 1.5625,0.27344 q 0,2.55209 1.02865,4.0625 1.02864,1.51042 3.08593,1.51042 1.48438,0 2.48698,-0.66406 1.01563,-0.67709 1.57552,-1.84896 v -6.71875 q -0.53385,-1.1849 -1.52343,-1.92708 -0.97657,-0.75521 -2.51303,-0.75521 -2.05729,0 -3.09895,1.69271 -1.04167,1.6927 -1.04167,4.37499 z"
			       style={{}}
			       id="path4849" />
			    <path
			       d="m 203.91666,513.27931 0.14323,2.44791 q 0.69011,-1.28906 1.83594,-1.99218 1.14583,-0.71615 2.72135,-0.71615 2.29167,0 3.48959,1.40625 1.19791,1.39323 1.19791,4.36198 v 8.58073 h -1.5625 v -8.56771 q 0,-2.42187 -0.91145,-3.42448 -0.89844,-1.0026 -2.56511,-1.0026 -1.66666,0 -2.69531,0.82031 -1.01563,0.82031 -1.47135,2.20052 v 9.97396 h -1.5625 v -14.08854 z"
			       style={{}}
			       id="path4851" />
			  </g>
			  <g
			     aria-label="React
			Redux"
			     transform="matrix(0.26458333,0,0,0.26458333,144.99166,1.8041667)"
			     style={(this.props.fun === false)?fontObj:Object.assign({}, fontObj, {fill:'#001A2E'})}
			     id="flowRoot4708">
			    <path
			       d="m 79.945312,485.4668 v 8.5677 h -1.5625 v -18.94531 h 6.315104 q 3.046875,0 4.67448,1.34115 Q 91,477.75846 91,480.32357 q 0,1.51041 -0.872396,2.70833 -0.859375,1.1849 -2.447917,1.73177 1.757813,0.44271 2.526042,1.58854 0.78125,1.13282 0.78125,2.85157 v 1.78385 q 0,0.88542 0.208333,1.58854 0.208333,0.70313 0.690104,1.14583 v 0.3125 h -1.588541 q -0.481771,-0.46875 -0.677084,-1.3151 -0.195312,-0.84635 -0.195312,-1.75781 v -1.73177 q 0,-1.74479 -1.132813,-2.7474 -1.119791,-1.01562 -3.13802,-1.01562 z m 0,-1.32813 h 4.570313 q 2.565104,0 3.75,-1.02864 1.184896,-1.04167 1.184896,-2.79948 0,-1.875 -1.184896,-2.87761 -1.171875,-1.01562 -3.567709,-1.01562 h -4.752604 z"
			       style={{}}
			       id="path4794" />
			    <path
			       d="m 100.80469,494.30794 q -2.734379,0 -4.440108,-1.92708 -1.705729,-1.94011 -1.705729,-4.97396 v -0.71615 q 0,-3.04687 1.71875,-5.02604 1.731771,-1.97916 4.283857,-1.97916 2.5651,0 3.95833,1.65364 1.40625,1.64063 1.40625,4.42709 v 1.21093 h -9.804687 v 0.42969 q 0,2.40885 1.210937,3.9974 1.223959,1.58854 3.3724,1.58854 1.28906,0 2.36979,-0.41667 1.08073,-0.42969 1.79687,-1.14583 l 0.66407,1.06771 q -0.74219,0.78125 -1.95313,1.30208 -1.19792,0.50781 -2.8776,0.50781 z m -0.14323,-13.29427 q -1.796878,0 -2.955732,1.28906 -1.158854,1.28907 -1.354167,3.26823 l 0.03906,0.0781 h 8.072916 v -0.32552 q 0,-1.875 -0.98958,-3.08594 -0.97657,-1.22396 -2.8125,-1.22396 z"
			       style={{}}
			       id="path4796" />
			    <path
			       d="m 118.12239,494.0345 q -0.14322,-0.74218 -0.22135,-1.27604 -0.0651,-0.53385 -0.0651,-1.09375 -0.72917,1.14584 -2.04427,1.90104 -1.31511,0.74219 -3.02084,0.74219 -2.07031,0 -3.21614,-1.09375 -1.13282,-1.10677 -1.13282,-2.99479 0,-1.88802 1.65365,-3.0599 1.65365,-1.17187 4.29687,-1.17187 h 3.46355 v -1.74479 q 0,-1.51042 -0.96355,-2.36979 -0.95052,-0.85938 -2.69531,-0.85938 -1.60156,0 -2.59114,0.78125 -0.97657,0.78125 -0.97657,1.96615 h -1.44531 l -0.026,-0.0781 q -0.0781,-1.58854 1.35417,-2.78646 1.44531,-1.21093 3.76302,-1.21093 2.31771,0 3.72396,1.18489 1.40625,1.1849 1.40625,3.39844 v 6.92708 q 0,0.74219 0.0781,1.44532 0.0911,0.70312 0.28646,1.39322 z m -5.18229,-1.10677 q 1.79688,0 3.11198,-0.84635 1.31511,-0.85938 1.78386,-2.20052 v -2.69531 h -3.47657 q -1.90104,0 -3.13802,0.91145 -1.23698,0.89844 -1.23698,2.17448 0,1.19792 0.78125,1.92709 0.79427,0.72916 2.17448,0.72916 z"
			       style={{}}
			       id="path4798" />
			    <path
			       d="m 128.68229,492.99284 q 1.4974,0 2.64323,-0.88542 1.15885,-0.88542 1.15885,-2.46094 h 1.39323 l 0.026,0.0781 q 0.0521,1.95312 -1.53646,3.26823 -1.58854,1.3151 -3.6849,1.3151 -2.8125,0 -4.38802,-1.97916 -1.5625,-1.97917 -1.5625,-5.06511 v -0.54687 q 0,-3.07292 1.5625,-5.05209 1.5625,-1.97916 4.375,-1.97916 2.26563,0 3.77604,1.36718 1.51042,1.36719 1.45834,3.59375 l -0.026,0.0781 h -1.39323 q 0,-1.69271 -1.10677,-2.69531 -1.10677,-1.01563 -2.70833,-1.01563 -2.21354,0 -3.29427,1.62761 -1.08073,1.6276 -1.08073,4.07552 v 0.54687 q 0,2.48698 1.08073,4.11458 1.08073,1.61459 3.30729,1.61459 z"
			       style={{}}
			       id="path4800" />
			    <path
			       d="m 139.71094,476.33919 v 3.60677 h 3.09896 v 1.27604 h -3.09896 v 9.17969 q 0,1.38021 0.49479,1.96615 0.50781,0.57291 1.34114,0.57291 0.28646,0 0.54688,-0.026 0.27344,-0.0391 0.63802,-0.11718 l 0.23438,1.15885 q -0.3125,0.15625 -0.78125,0.2474 -0.45573,0.10416 -0.91146,0.10416 -1.48438,0 -2.31771,-0.9375 -0.82031,-0.9375 -0.82031,-2.96875 V 481.222 h -2.4349 v -1.27604 h 2.4349 v -3.60677 z"
			       style={{}}
			       id="path4802" />
			    <path
			       d="m 79.945312,518.80014 v 8.56771 h -1.5625 v -18.94531 h 6.315104 q 3.046875,0 4.67448,1.34114 Q 91,511.09181 91,513.65691 q 0,1.51042 -0.872396,2.70833 -0.859375,1.1849 -2.447917,1.73178 1.757813,0.4427 2.526042,1.58854 0.78125,1.13281 0.78125,2.85156 v 1.78385 q 0,0.88542 0.208333,1.58854 0.208333,0.70313 0.690104,1.14584 v 0.3125 h -1.588541 q -0.481771,-0.46875 -0.677084,-1.31511 -0.195312,-0.84635 -0.195312,-1.75781 v -1.73177 q 0,-1.74479 -1.132813,-2.74739 -1.119791,-1.01563 -3.13802,-1.01563 z m 0,-1.32812 h 4.570313 q 2.565104,0 3.75,-1.02865 1.184896,-1.04167 1.184896,-2.79948 0,-1.875 -1.184896,-2.8776 -1.171875,-1.01563 -3.567709,-1.01563 h -4.752604 z"
			       style={{}}
			       id="path4804" />
			    <path
			       d="m 100.80469,527.64129 q -2.734379,0 -4.440108,-1.92709 -1.705729,-1.9401 -1.705729,-4.97396 v -0.71614 q 0,-3.04688 1.71875,-5.02604 1.731771,-1.97917 4.283857,-1.97917 2.5651,0 3.95833,1.65365 1.40625,1.64062 1.40625,4.42708 v 1.21094 h -9.804687 v 0.42968 q 0,2.40886 1.210937,3.9974 1.223959,1.58854 3.3724,1.58854 1.28906,0 2.36979,-0.41667 1.08073,-0.42968 1.79687,-1.14583 l 0.66407,1.06771 q -0.74219,0.78125 -1.95313,1.30208 -1.19792,0.50782 -2.8776,0.50782 z m -0.14323,-13.29427 q -1.796878,0 -2.955732,1.28906 -1.158854,1.28906 -1.354167,3.26823 l 0.03906,0.0781 h 8.072916 v -0.32552 q 0,-1.875 -0.98958,-3.08594 -0.97657,-1.22395 -2.8125,-1.22395 z"
			       style={{}}
			       id="path4806" />
			    <path
			       d="m 108.59114,520.44076 q 0,-3.37239 1.41928,-5.39062 1.43229,-2.03125 3.98437,-2.03125 1.44531,0 2.52604,0.61198 1.09375,0.61198 1.8099,1.73177 v -8.30729 h 1.5625 v 20.3125 h -1.27604 l -0.22136,-2.04427 q -0.71614,1.11979 -1.82291,1.71875 -1.10678,0.59896 -2.60417,0.59896 -2.52604,0 -3.95833,-1.875 -1.41928,-1.88803 -1.41928,-5.05209 z m 1.5625,0.27344 q 0,2.55209 1.01563,4.0625 1.02865,1.51042 3.08594,1.51042 1.51041,0 2.51302,-0.66406 1.0026,-0.67709 1.5625,-1.86198 v -6.67969 q -0.53386,-1.19792 -1.51042,-1.95312 -0.97656,-0.75521 -2.53906,-0.75521 -2.05729,0 -3.09896,1.69271 -1.02865,1.67968 -1.02865,4.37499 z"
			       style={{}}
			       id="path4808" />
			    <path
			       d="m 133.26562,525.07618 q -0.67708,1.23698 -1.82291,1.90104 -1.13282,0.66407 -2.69532,0.66407 -2.22656,0 -3.48958,-1.51042 -1.26302,-1.51042 -1.26302,-4.6875 v -8.16406 h 1.5625 v 8.1901 q 0,2.57813 0.8724,3.69792 0.88541,1.11979 2.5,1.11979 1.75781,0 2.79948,-0.78125 1.05468,-0.79427 1.48437,-2.16146 v -10.0651 h 1.5625 v 14.08854 h -1.38021 z"
			       style={{}}
			       id="path4810" />
			    <path
			       d="m 143.31771,519.03452 3.8151,-5.75521 h 1.84896 l -4.77865,6.91406 4.97396,7.17448 h -1.82291 l -4.01042,-6.00261 -4.01042,6.00261 h -1.84896 l 4.98698,-7.17448 -4.77864,-6.91406 h 1.83593 z"
			       style={{}}
			       id="path4812" />
			  </g>
			  <g
			     aria-label="HTML5"
			     transform="matrix(0.26458333,0,0,0.26458333,174.88958,58.160416)"
			     style={(this.props.fun === false)?fontObj:Object.assign({}, fontObj, {fill:'white'})}
			     id="flowRoot4716">
			    <path
			       d="m 92.315104,494.0345 h -1.5625 v -8.72395 H 79.984375 v 8.72395 h -1.5625 v -18.95833 h 1.5625 v 8.90625 h 10.768229 v -8.90625 h 1.5625 z"
			       style={{}}
			       id="path4783" />
			    <path
			       d="m 109.69792,476.4043 h -6.45834 v 17.6302 h -1.54948 v -17.6302 h -6.679684 v -1.32813 h 14.687504 z"
			       style={{}}
			       id="path4785" />
			    <path
			       d="m 121.85937,491.76888 h 0.0781 l 7.05729,-16.69271 h 1.90104 v 18.95833 h -1.5625 v -15.92447 l -0.0781,-0.013 -6.79688,15.93749 h -1.11979 l -6.95313,-16.32812 -0.0781,0.013 v 16.3151 h -1.5625 v -18.95833 h 2.08333 z"
			       style={{}}
			       id="path4787" />
			    <path
			       d="m 137.32812,492.7194 h 9.79167 v 1.3151 h -11.35417 v -18.95833 h 1.5625 z"
			       style={{}}
			       id="path4789" />
			    <path
			       d="m 150.46615,485.50586 1.04166,-10.42969 h 9.21875 v 1.44531 h -7.8776 l -0.70313,6.82292 q 0.71615,-0.63802 1.57552,-1.0026 0.8724,-0.36459 2.33073,-0.40365 2.4349,-0.0391 3.90625,1.60156 1.48438,1.64063 1.48438,4.45313 0,2.8776 -1.44531,4.59635 -1.44532,1.71875 -4.33594,1.71875 -2.34375,0 -3.90625,-1.30208 -1.5625,-1.31511 -1.4974,-3.88021 l 0.026,-0.0781 h 1.40625 q 0,1.91406 1.08073,2.92968 1.08073,1.01563 2.89063,1.01563 2.12239,0 3.16406,-1.27604 1.05469,-1.27605 1.05469,-3.69792 0,-2.1224 -1.05469,-3.3724 -1.04167,-1.25 -2.94271,-1.25 -1.80989,0 -2.77344,0.58594 -0.95052,0.57292 -1.3802,1.74479 z"
			       style={{}}
			       id="path4791" />
			  </g>
			  <g
			     aria-label="CSS3"
			     transform="matrix(0.26458333,0,0,0.26458333,141.81667,108.96042)"
			     style={(this.props.fun === false)?fontObj:Object.assign({}, fontObj, {fill:'#001A2E'})}
			     id="flowRoot4724">
			    <path
			       d="m 91.48177,488.11003 0.02604,0.0781 q 0.05208,2.78646 -1.822916,4.45313 -1.875,1.66666 -4.921875,1.66666 -3.164063,0 -5.104167,-2.27864 -1.940104,-2.29167 -1.940104,-5.92448 v -3.08594 q 0,-3.61979 1.940104,-5.91146 1.940104,-2.30469 5.104167,-2.30469 3.085937,0 4.934895,1.62761 1.861979,1.6276 1.809896,4.47916 l -0.02604,0.0781 h -1.458333 q 0,-2.29167 -1.40625,-3.56771 -1.393229,-1.28906 -3.854166,-1.28906 -2.486979,0 -3.997396,1.92708 -1.497396,1.92709 -1.497396,4.9349 v 3.11198 q 0,3.04687 1.497396,4.97396 1.510417,1.91406 3.997396,1.91406 2.460937,0 3.854166,-1.25 1.40625,-1.25 1.40625,-3.63281 z"
			       style={{}}
			       id="path4774" />
			    <path
			       d="m 106.19531,489.42513 q 0,-1.45833 -1.15885,-2.40885 -1.15886,-0.95053 -3.93229,-1.78386 -3.033859,-0.83333 -4.622401,-2.07031 -1.57552,-1.25 -1.57552,-3.38542 0,-2.17448 1.770833,-3.56771 1.770833,-1.40625 4.518228,-1.40625 2.89063,0 4.64844,1.58855 1.75781,1.57552 1.70573,4.01041 l -0.026,0.0781 h -1.45834 q 0,-1.875 -1.36718,-3.11198 -1.35417,-1.23698 -3.50261,-1.23698 -2.213541,0 -3.476561,1.05469 -1.25,1.04166 -1.25,2.5651 0,1.44531 1.132812,2.38281 1.145833,0.92448 4.023439,1.73177 2.99479,0.8724 4.57031,2.14844 1.57552,1.27604 1.57552,3.38542 0,2.21354 -1.84896,3.56771 -1.83593,1.34114 -4.63541,1.34114 -2.799482,0 -4.882816,-1.41927 -2.083333,-1.43229 -2.018229,-4.16667 l 0.02604,-0.0781 h 1.445312 q 0,2.20052 1.653646,3.28125 1.653646,1.06771 3.776045,1.06771 2.17448,0 3.54166,-0.98959 1.36719,-1.0026 1.36719,-2.57812 z"
			       style={{}}
			       id="path4776" />
			    <path
			       d="m 122.28906,489.42513 q 0,-1.45833 -1.15885,-2.40885 -1.15886,-0.95053 -3.93229,-1.78386 -3.03386,-0.83333 -4.6224,-2.07031 Q 111,481.91211 111,479.77669 q 0,-2.17448 1.77083,-3.56771 1.77084,-1.40625 4.51823,-1.40625 2.89063,0 4.64844,1.58855 1.75781,1.57552 1.70573,4.01041 l -0.026,0.0781 h -1.45834 q 0,-1.875 -1.36718,-3.11198 -1.35417,-1.23698 -3.50261,-1.23698 -2.21354,0 -3.47656,1.05469 -1.25,1.04166 -1.25,2.5651 0,1.44531 1.13281,2.38281 1.14583,0.92448 4.02344,1.73177 2.99479,0.8724 4.57031,2.14844 1.57552,1.27604 1.57552,3.38542 0,2.21354 -1.84896,3.56771 -1.83593,1.34114 -4.63541,1.34114 -2.79948,0 -4.88282,-1.41927 -2.08333,-1.43229 -2.01822,-4.16667 l 0.026,-0.0781 h 1.44531 q 0,2.20052 1.65365,3.28125 1.65364,1.06771 3.77604,1.06771 2.17448,0 3.54166,-0.98959 1.36719,-1.0026 1.36719,-2.57812 z"
			       style={{}}
			       id="path4778" />
			    <path
			       d="m 132.44531,483.64388 q 2.07031,0 3.13802,-1.02865 1.06771,-1.02864 1.06771,-2.65625 0,-1.6927 -0.98958,-2.76041 -0.98959,-1.06771 -2.92969,-1.06771 -1.82292,0 -3.02083,1.09375 -1.19792,1.08073 -1.19792,2.8125 h -1.45833 l -0.026,-0.0781 q -0.0651,-2.21354 1.57553,-3.68489 1.64062,-1.47136 4.1276,-1.47136 2.52604,0 3.9974,1.36719 1.48437,1.36719 1.48437,3.84115 0,1.40625 -0.82031,2.55208 -0.80729,1.14583 -2.22656,1.70573 1.64062,0.52083 2.51302,1.70573 0.88541,1.18489 0.88541,2.91667 0,2.53906 -1.60156,3.98437 -1.60156,1.43229 -4.16667,1.43229 -2.40885,0 -4.27083,-1.41927 -1.84896,-1.43229 -1.78385,-3.91927 l 0.026,-0.0781 h 1.45833 q 0,1.75781 1.28906,2.92968 1.28907,1.17188 3.28125,1.17188 1.97917,0 3.09896,-1.09375 1.11979,-1.09375 1.11979,-2.95573 0,-2.00521 -1.19791,-2.98177 -1.1849,-0.98959 -3.3724,-0.98959 h -1.74479 v -1.32812 z"
			       style={{}}
			       id="path4780" />
			  </g>
			  <g
			     aria-label="Javascript"
			     transform="matrix(0.26458333,0,0,0.26458333,75.670834,81.44375)"
			     style={(this.props.fun === false)?fontObj:Object.assign({}, fontObj, {fill:'#7B96A9'})}
			     id="flowRoot4753">
			    <path
			       d="m 86.677083,475.07617 h 1.5625 v 13.46354 q 0,2.64323 -1.549479,4.20573 -1.536458,1.5625 -3.984375,1.5625 -2.604167,0 -4.127604,-1.38021 -1.523438,-1.3802 -1.458333,-4.03645 l 0.02604,-0.0781 h 1.458334 q 0,2.13542 1.041666,3.16406 1.054688,1.01563 3.059896,1.01563 1.731771,0 2.851562,-1.19792 1.119792,-1.21094 1.119792,-3.25521 z"
			       style={{}}
			       id="path4854" />
			    <path
			       d="m 101.61198,494.0345 q -0.14323,-0.74218 -0.22136,-1.27604 -0.0651,-0.53385 -0.0651,-1.09375 -0.72917,1.14584 -2.044271,1.90104 -1.315104,0.74219 -3.020833,0.74219 -2.070313,0 -3.216146,-1.09375 -1.132812,-1.10677 -1.132812,-2.99479 0,-1.88802 1.653645,-3.0599 1.653646,-1.17187 4.296875,-1.17187 h 3.463542 v -1.74479 q 0,-1.51042 -0.96354,-2.36979 -0.950523,-0.85938 -2.695314,-0.85938 -1.601563,0 -2.591146,0.78125 -0.976562,0.78125 -0.976562,1.96615 h -1.445313 l -0.02604,-0.0781 q -0.07813,-1.58854 1.354167,-2.78646 1.445313,-1.21093 3.763021,-1.21093 2.317709,0 3.723959,1.18489 1.40625,1.1849 1.40625,3.39844 v 6.92708 q 0,0.74219 0.0781,1.44532 0.0911,0.70312 0.28646,1.39322 z m -5.182293,-1.10677 q 1.796875,0 3.111979,-0.84635 1.315104,-0.85938 1.783854,-2.20052 v -2.69531 h -3.476562 q -1.901042,0 -3.138021,0.91145 -1.236979,0.89844 -1.236979,2.17448 0,1.19792 0.78125,1.92709 0.79427,0.72916 2.174479,0.72916 z"
			       style={{}}
			       id="path4856" />
			    <path
			       d="m 110.81771,490.50586 0.45573,1.65364 h 0.0781 l 0.49479,-1.65364 3.82813,-10.5599 h 1.61458 l -5.35156,14.08854 h -1.25 l -5.36458,-14.08854 h 1.61458 z"
			       style={{}}
			       id="path4858" />
			    <path
			       d="m 128.59114,494.0345 q -0.14322,-0.74218 -0.22135,-1.27604 -0.0651,-0.53385 -0.0651,-1.09375 -0.72917,1.14584 -2.04427,1.90104 -1.31511,0.74219 -3.02084,0.74219 -2.07031,0 -3.21614,-1.09375 -1.13282,-1.10677 -1.13282,-2.99479 0,-1.88802 1.65365,-3.0599 1.65365,-1.17187 4.29687,-1.17187 h 3.46355 v -1.74479 q 0,-1.51042 -0.96355,-2.36979 -0.95052,-0.85938 -2.69531,-0.85938 -1.60156,0 -2.59114,0.78125 -0.97657,0.78125 -0.97657,1.96615 h -1.44531 l -0.026,-0.0781 q -0.0781,-1.58854 1.35417,-2.78646 1.44531,-1.21093 3.76302,-1.21093 2.31771,0 3.72396,1.18489 1.40625,1.1849 1.40625,3.39844 v 6.92708 q 0,0.74219 0.0781,1.44532 0.0911,0.70312 0.28646,1.39322 z m -5.18229,-1.10677 q 1.79688,0 3.11198,-0.84635 1.31511,-0.85938 1.78386,-2.20052 v -2.69531 h -3.47657 q -1.90104,0 -3.13802,0.91145 -1.23698,0.89844 -1.23698,2.17448 0,1.19792 0.78125,1.92709 0.79427,0.72916 2.17448,0.72916 z"
			       style={{}}
			       id="path4860" />
			    <path
			       d="m 142.43229,490.42773 q 0,-0.98958 -0.82031,-1.71875 -0.80729,-0.74218 -2.92969,-1.21093 -2.5,-0.52084 -3.72396,-1.41927 -1.21093,-0.91146 -1.21093,-2.53907 0,-1.61458 1.35416,-2.73437 1.36719,-1.11979 3.60677,-1.11979 2.35677,0 3.76302,1.19791 1.40625,1.1849 1.34115,2.92969 l -0.026,0.0781 h -1.44531 q 0,-1.15886 -1.00261,-2.01823 -0.98958,-0.85938 -2.63021,-0.85938 -1.69271,0 -2.55208,0.74219 -0.85938,0.74219 -0.85938,1.73177 0,0.96354 0.72917,1.60156 0.74219,0.625 2.94271,1.10677 2.47396,0.54688 3.73698,1.52344 1.27604,0.97656 1.27604,2.63021 0,1.75781 -1.43229,2.86458 -1.41927,1.09375 -3.76302,1.09375 -2.56511,0 -4.03646,-1.22396 -1.45833,-1.22395 -1.38021,-2.91666 l 0.026,-0.0781 h 1.43229 q 0.0911,1.52344 1.23698,2.21354 1.15886,0.69011 2.72136,0.69011 1.69271,0 2.66927,-0.75521 0.97656,-0.75521 0.97656,-1.8099 z"
			       style={{}}
			       id="path4862" />
			    <path
			       d="m 152.74479,492.99284 q 1.4974,0 2.64323,-0.88542 1.15885,-0.88542 1.15885,-2.46094 h 1.39323 l 0.026,0.0781 q 0.0521,1.95312 -1.53646,3.26823 -1.58854,1.3151 -3.6849,1.3151 -2.8125,0 -4.38802,-1.97916 -1.5625,-1.97917 -1.5625,-5.06511 v -0.54687 q 0,-3.07292 1.5625,-5.05209 1.5625,-1.97916 4.375,-1.97916 2.26563,0 3.77604,1.36718 1.51042,1.36719 1.45834,3.59375 l -0.026,0.0781 h -1.39323 q 0,-1.69271 -1.10677,-2.69531 -1.10677,-1.01563 -2.70833,-1.01563 -2.21354,0 -3.29427,1.62761 -1.08073,1.6276 -1.08073,4.07552 v 0.54687 q 0,2.48698 1.08073,4.11458 1.08073,1.61459 3.30729,1.61459 z"
			       style={{}}
			       id="path4864" />
			    <path
			       d="m 167.77083,481.28711 -1.10677,-0.0781 q -1.47135,0 -2.42187,0.83334 -0.9375,0.82031 -1.34115,2.26562 v 9.72656 h -1.5625 v -14.08854 h 1.36719 l 0.19531,2.34375 v 0.15625 q 0.61198,-1.30208 1.64063,-2.03125 1.02864,-0.72916 2.44791,-0.72916 0.28646,0 0.54688,0.0521 0.26041,0.0391 0.42969,0.0911 z"
			       style={{}}
			       id="path4866" />
			    <path
			       d="m 172.19791,494.0345 h -1.54948 v -14.08854 h 1.54948 z m 0,-18.12499 h -1.54948 v -2.1875 h 1.54948 z"
			       style={{}}
			       id="path4868" />
			    <path
			       d="m 187.83594,487.38086 q 0,3.16406 -1.43229,5.05208 -1.4323,1.875 -3.94532,1.875 -1.47135,0 -2.59114,-0.55989 -1.10677,-0.5599 -1.8099,-1.58855 v 7.29167 h -1.5625 v -19.50521 h 1.19792 l 0.28646,2.20052 q 0.70312,-1.17187 1.82291,-1.80989 1.11979,-0.65104 2.63021,-0.65104 2.53906,0 3.97136,2.03125 1.43229,2.01823 1.43229,5.39062 z m -1.5625,-0.27344 q 0,-2.68229 -1.06771,-4.375 -1.06771,-1.69271 -3.125,-1.69271 -1.54948,0 -2.53906,0.74219 -0.97657,0.74219 -1.48438,1.92708 v 6.82292 q 0.54688,1.14583 1.54948,1.8099 1.0026,0.65104 2.5,0.65104 2.04427,0 3.09896,-1.53646 1.06771,-1.53646 1.06771,-4.07552 z"
			       style={{}}
			       id="path4870" />
			    <path
			       d="m 193.77344,476.33919 v 3.60677 h 3.09896 v 1.27604 h -3.09896 v 9.17969 q 0,1.38021 0.49479,1.96615 0.50781,0.57291 1.34114,0.57291 0.28646,0 0.54688,-0.026 0.27344,-0.0391 0.63802,-0.11718 l 0.23438,1.15885 q -0.3125,0.15625 -0.78125,0.2474 -0.45573,0.10416 -0.91146,0.10416 -1.48438,0 -2.31771,-0.9375 -0.82031,-0.9375 -0.82031,-2.96875 V 481.222 h -2.4349 v -1.27604 h 2.4349 v -3.60677 z"
			       style={{}}
			       id="path4872" />
			  </g>
			</g>
			</svg>

		);
	}
}

export default Strengths;
