import React from 'react';

export default class Phone extends React.Component {
	constructor(props){
		super(props);
	}

	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} viewBox="0 0 512 512"><path d="M493.397 24.615l-104-23.997c-11.314-2.611-22.879 3.252-27.456 13.931l-48 111.997a24 24 0 0 0 6.862 28.029l60.617 49.596c-35.973 76.675-98.938 140.508-177.249 177.248l-49.596-60.616a24 24 0 0 0-28.029-6.862l-111.997 48C3.873 366.516-1.994 378.08.618 389.397l23.997 104C27.109 504.204 36.748 512 48 512c256.087 0 464-207.532 464-464 0-11.176-7.714-20.873-18.603-23.385z"/>
			</svg>
		);
	}
}
