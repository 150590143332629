import React from 'react';
// import styles from '../css/dummy.css';
// import Section from './section';

export default class DummyData extends React.Component {
	render() {
		return <span >
					{(this.props.value === '')? <span className='DummyData'>{this.props.name}</span>: this.props.value}
			</span>;
	}
}
