import React from 'react';

export default class LinkedIn extends React.Component {
	constructor(props){
		super(props);
	}

	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} viewBox="0 0 448 512">
			<path d="M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z"/>
			</svg>
		);
	}
}
