function actionChangeLayout(layout){
	return {
		type:'CHANGE LAYOUT',
		layout:layout
	};
}

function determineFontLink(layout){
	let font = (layout === 'professional')? 'Londrina+Solid|Shadows+Into+Light+Two'
		: 'Roboto';
	return 'https://fonts.googleapis.com/css?family=' + font;
}
export function asyncActionChangeLayout(layout, linkNode){
	var changeLayoutPromise = new Promise((resolve,reject) =>{
		resolve(actionChangeLayout(layout));
	});
	var determineLinkPromise = new Promise((resolve, reject) =>{
		resolve(determineFontLink(layout));
	});

	return (dispatch) =>{
		return determineLinkPromise
			.then((link)=> {
				linkNode.setAttribute('href',link);})
			.then(()=>{
				return changeLayoutPromise
					.then(changeLayoutObj => dispatch(changeLayoutObj));
			});
	};
}
