import React from 'react';

class Glass extends React.Component {
	constructor(props){
		super(props);
	}

	render() {
		return (
			<svg className='circle' width={this.props.width} viewBox="0 0 200 85" >
				<path d="M85,70
								 A30,50 0 0,0 100 80"
								  stroke='black' strokeWidth="3"  stroke={this.props.frame} fill='none'/>
				<path d='M100,80
								 A30,90 0 0,0 115 70'
								 stroke={this.props.frame} fill='none' strokeWidth="3"/>
				<rect x="20" y="37" width="20" height="4" fill={this.props.frame}/>
				<rect x="89" y="37" width="21" height="5" fill={this.props.frame} />
				<rect x="158" y="37" width="21" height="5"  fill={this.props.frame}/>
				<ellipse  cx="65" cy="37" rx="25" ry="23" fill="white" stroke={this.props.frame} fill='transparent' strokeWidth="6"/>
				<ellipse  cx="75" cy="37" rx="5" ry='5.5' stroke={this.props.eyeBorder} fill="black"  />

				<ellipse  cx="134" cy="37" rx="25" ry="23" fill="white" stroke={this.props.frame} fill='none' strokeWidth="6"/>
				<ellipse  cx="124" cy="37" rx="5" ry='5.5' stroke={this.props.eyeBorder}fill="black" />

			</svg>
		);
	}
}

export default Glass;
