import React from 'react';
import styles from '../sass/components/projects.scss';
import Section from './section';
import DummyData from './dummy';

export let dummyProjectsList = {
	1: {title: 'Forky(2016-current)', description:['Building an app to simplify university life IC#, GoLang, App Engine']},
	2: {title: 'Build\'n-shoot(2016-current)' , description:['Building a VR game IC#, Unity']},
	3: {title: 'Leadup(2015-current)', description:['Project aimed to jelp students and adults get mentors and be mentees [PHP, Yii, DynamoDB, Lambda, EC2]']},
	4: {title: 'AutomateMe(2014-current)',description:['Project aimed to automate marketing on social media sites IC#, Selenium, MongoDB']}
};


export default class Projects extends React.Component{
	constructor(props){
		super(props);
		this.showProjects = this.showProjects.bind(this);
	}

	showDummyData() {
		return (Object.keys(dummyProjectsList)).map((element) => {
			return <div className={'projects'+element} key={'projects'+element}>
				{(this.props.layout==='professional')? <div className='projectDesignContainer'>
					<div className='projectDesignContainerBox'> </div>
					<h3>{((dummyProjectsList)[element]).title } </h3>
				</div>:
					<h3>{((dummyProjectsList)[element]).title } </h3> }
				<ul>
					{(((dummyProjectsList)[element]).description).map((data,index) => {
						return <li key={element + index}> <span> {data} </span></li> ;
					})}
				</ul>
			</div>;
		});
	}
	showProjects(){
		return (Object.keys(this.props.projectsList)).map((element) => {
			return <div className={'projects'+element} key={'projects'+element}>
				{(this.props.layout==='professional')? <div className='projectDesignContainer'>
					<div className='projectDesignContainerBox'> </div>
					<h3>{((this.props.projectsList)[element]).title } </h3>
				</div>:
					<h3>{((this.props.projectsList)[element]).title } </h3> }
				<ul>
					{(((this.props.projectsList)[element]).description).map((data,index) => {
						return <li key={element + index}> <span>{data}</span> </li> ;
					})}
				</ul>
			</div>;
		});
	}
	render() {
		return <div className={this.props.layout === 'professional'? 'projectsContainer' : 'projectsContainerFun'}>
			<Section name="Projects" layout={this.props.layout}/>
			{(typeof this.props.projectsList === 'undefined') ?this.showDummyData()
				:this.showProjects()}
		</div>;
	}
}
