import React from 'react';
import styles from '../sass/components/mainSubHeadings.scss';
import DummyData from './dummy';

export default class MainSubHeading extends React.Component {
	render() {
		if(this.props.layout==='professional'){
			return <div className='mainSubHeadingContainer'>
				<div className='designBlock'> </div>
				<div>
					<h3 className='mainHeading'>
						<DummyData name='mainHeading' value={this.props.mainHeading}/>
					</h3>
					<h4>
						<DummyData name='subHeading' value={this.props.subHeading}/>
					</h4>
				</div>
			</div>;
		} else {
			return (<div className='mainSubHeadingContainerFun'>
				<h3> <DummyData name='mainHeading' value={this.props.mainHeading}/> </h3>
				<h4> <DummyData name='subHeading' value={this.props.subHeading}/> </h4>
			</div>
			);
		}

	}
}
