import React from 'react';
import styles from  '../../../sass/components/stationaryWebsiteHeader.scss';
import {NavLink} from 'react-router-dom';
import Glass from './glass';
/*
	<NavLink to='/Projects'> Projects </NavLink>
*/
const WebsiteHeader = (props) =>{
	let isProfessional = props.layout.layout  === 'professional'? true:false;
	let fontStyle = {};
	fontStyle['fontFamily'] = isProfessional?'Roboto':'Helvetica';
	return <div className='stationaryWebsiteHeader'>
		<nav className='navBarContainer' className="navBarContainer">
			<NavLink to='/home' > <span style={fontStyle}>Home </span>
			</NavLink>
			<NavLink to='/Resume'  > <span style={fontStyle}>Resume </span></NavLink>

		</nav>
		<div className='submitButton'>
			<button
				style={fontStyle}
				onClick={()=>{props.changeLayout(
					props.layout
				);}}>
				{(isProfessional  === true)? 'Fun Layout' : 'Professional Layout'} </button>
		</div>
	</div>;
};

export default WebsiteHeader;
