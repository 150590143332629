let personInfoArray = ['Manjeet Kaur',
	'Frontend Engineer',
	'I am a frontend engineer specializing in developing creative and advanced React applications'];

let contactInfoObject = {Email:'manjeet175@gmail.com',
	Tel:'647 868 7126',
	Location:'Toronto, ON',
	LinkedIn: 'www.linkedin.com/in/kaurmanjeet'};


export let headerContainer = {
	'personInfoArray': personInfoArray,
	'contactInfoObject':contactInfoObject
};
export let position1 = {
	startDate: '08/2017',
	endDate: '06/2018',
	location: 'Toronto, ON',
	positionTitle: 'Frontend Engineer/Co-Founder',
	companyName:'ResumeDoodle',
	workTitle: 'Tasks/Achievements',
	description: [
		'React Advanced Patterns, Git, Redux-thunk, DOM manipulation, ReactRouter, GraphQL, Apollo-Client, Webpack, TDD',
		'Co-created a product that empowers job seekers to transform their resume content to utilize the latest creative resume design layouts dynamically',
		'Solely designed and developed a highly performant front-end web UI utilizing modern languages and tools including React, Redux and GraphQL',
	]
};
export let position2 = {
	startDate: '02/2016',
	endDate: '07/2017',
	location: 'Toronto, ON',
	positionTitle: 'Technical Analyst',
	companyName:'IBM - Incentive Compensation Management',
	workTitle: 'Tasks/Achievements',
	description: [
		'SQL Server(complex query performance analysis), Tomcat, JIRA, ICM, log file analysis',
		'Primary person responsible for handling the technical inquiries of a huge multi-million dollar IBM client, resulting in a successful client migration onto the new IBM platform earning IBM a huge upsurge in customers',
		'Represented IBM at a prestigious annual ICM conference(Vision 2017), showcasing over 100 multinational companies',
	]
};

export let position3 = {
	startDate: '07/2015',
	endDate: '12/2015',
	location: 'North York, ON',
	positionTitle: 'Project Coordinator',
	companyName:'York University - Lassonde School Of Engineering',
	workTitle: 'Tasks/Achievements',
	description: [
		'Engineered an innovative LED showcase, boasting over 1500 individually controlled LEDs, that promoted the mindset, "unity is strength" successfully leading to a huge alumni interaction',
	]
};

export let position4 = {
	startDate: '05/2012',
	endDate: '07/2013',
	location: 'Markham, ON',
	positionTitle: 'Technical Resolution Specialist - Intern',
	companyName:'IBM  - Websphere Commerce',
	workTitle: 'Tasks/Achievements',
	description: [
		'DB2, Java Source Code Analysis, Log file analysis, effective communication of technical concepts',
		'Analyzed IBM Websphere Commerce code to assist clients with setting up store, manage orders and contracts',
	]
};

export let position5 = {
	startDate: '08/2018',
	endDate: '05/2020',
	location: 'Mississauga, ON',
	positionTitle: 'Senior Frontend Engineer',
	companyName:'Intuit',
	workTitle: 'Tasks/Achievements',
	description: [
		`Led Frontend development for multiple core features of the Intuit's Workpapers team in Canada using the latest technologies
like React, Apollo Graphql, Enzyme, and Chai.`,
		`Minimized tech debt by going the extra mile in my contractor role to improve and refactor code in an effort to ensure code
base readability and scalability are above standards.`,
		'Spearheaded cross-team communication in the US and Canada thus helping to meet timelines and feature requirements.'
	]
};

export let position6 = {
	startDate: '07/2020',
	endDate: 'present',
	location: 'Waterloo, ON',
	positionTitle: 'Senior Frontend Engineer',
	companyName:'Carta',
	workTitle: 'Tasks/Achievements',
	description: [
		`Enhanced the development experience and boosted team productivity by moving frontend code out of the monolith`,
		`Designed and developed frontend architectures for various features. Led development efforts using React, Jest, and React Query`,
		'Authored technical documentation outlining product architecture and the rationale behind key technical decisions',
		`Led weekly frontend syncs to enhance communication and identify opportunities for process optimization across products.`,
		// `Guided junior developers by breaking projects into manageable tasks and providing detailed code reviews and feedback. Supported their successful transition from intern to full-time roles and promotions`
	]
};

export let positionList =[position6, position5, position1,position2];

export let Education2 = {
	startDate: '08/2008',
	endDate: '06/2015',
	location: 'Toronto, ON',
	degree: 'BEng. Honours Computer Engineering',
	universityName:'York University',
	awards:[]
};


export let AwardsList = {
	0: ['Bergeron Medal Of Distinction(05/2015)','Awarded to engineering students that demonstrate the greatest potential of combining their engineering background and entrepreneurial spirit to create a successful business'],
	1: ['NEXT 36 Canada Entrepreneurial Leadership Initiative (01/2015)','Selected among 670 student leaders across Canada to participate in an 8-month incubator  program which provides talented young entrepreneurs with mentorship and capital on their business venture'],
	// 2: ['COMDEV Engineering Design Awards (05-2014)', 'Awarded to innovative final year engineering project with a good go to market strategy'],
	3: ['Rob Tiffin Leadership Award(04-2014)','Awarded to students who made the greatest contributions to the development, growth and vitality of the University'],
	// 4: ['Science & Engineering Scholarship(08/2009)', 'Entrance scholarship']
};


export let EducationList = [Education2];

export let skills = {
	1: {skill: 'React',level : 4.5},
	2: {skill: 'State Management Solutions - Redux, Apollo',level : 4},
	3: {skill: 'Javascript',level : 4},
	4: {skill: 'GraphQL',level : 4},
	5: {skill: 'HTML5 & CSS3',level : 3.5},
};

export let ProjectsList = {
	1: {title: 'Front-End Engineer - Personal Portfolio', description:['Technical Skills developed: React, Redux, CSS animation, SVG, SASS, Webpack, Babel',
		'Designed and developed a website to showcase my creative spark, give a flavour of my personality and highlight my passion projects',
		'www.iammanjeet.com']},
	3: {title: 'Creative Content Developer - SafetyFirst' , description:['Skills developed: JDK, Screenflow(video editor), Photoshop','Worked in a team to develop a gesture-based app, which allows users to seek help when they\'re in danger, a project that won second place at final year engineering capstone project',
		'Produced an introduction video for the app which received the Best Multimedia Award (Video available at https://goo.gl/y76p1v)']},
	4: {title: 'Founding Chair - Lassonde Student Government' , description:['Skills Developed: Leadership, Task delegation, "Never give up attitude", Public Speaking, Conflict Resolution','Founded the organizational structure and established the core initiatives to positively impact over 1500 university students',
		'Recruited leads to run the initiatives and within a few months, expanded the government organization from 7 to 60 active members']},
	5: {title: 'Co-founder - W.I.S.E', description:['Skills Developed: Leadership, Creative thinking, Marketing, Time management',
		'Created an environment to empower women in science and engineering to look at the bigger picture of the tech world and figure out how they can make their skill-set shine',
		'Organized coding lessons and inspiring seminars with distinguished women in the technical field']},
	6:{title: 'Finance & Marketing Director - YURT', description:['Skills Developed: Public Speaking, Out of the box marketing, Time management',
		'Fundraised $70 000 in two years to compete in internationally acclaimed competitions: University Rover Challenge & NASA Lunabotics Mining Competition']},
};

export let OtherSkills = {
	1 : {category: 'API', list:'GraphQL, REST API'},
	3 : {category: 'React Ecosystem', list:'Redux, Jest, Express, ReactRouter, Babel, Webpack, Apollo client'},
	4 : {category: 'CSS', list:'Sass, bootstrap, CSS grid, Flexbox'},
	5 : {category: 'Design Tools', list:'Adobe Photoshop, Inkscape, Sketch'},
	6	: {category: 'Technical', list:'JavaScript ES6, ES7, Git, Apollo Client, DOM Manipulation'},
	7 : {category: 'Soft Skills', list:'Leadership, Team-Player, Project Management, Public-speaking'},
};

export let InterestList = ['Learning new things',
	'Yoga', '"Be the change you want to see in the world" - Gandhi',
	'dancing'];
