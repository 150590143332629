import React from 'react';
import WebsiteHeader from './components/webSiteHeader.js';
import ProjectsData from '../data/projectsData';
import Project from './components/project.js';

class Projects extends React.Component{
	constructor(props){
		super(props);
		this.updateLayout = this.updateLayout.bind(this);
		this.resetLayout = this.resetLayout.bind(this);
	}

	updateLayout(e){
		console.log('UpdateLayout was clicked');
		console.log(e);
		let currentProjectId = e.currentTarget.id;
		//console.log(e.target);
		console.log(e.currentTarget.id);

		let sibling = document.getElementById(currentProjectId).nextSibling;
		let previousSibling = document.getElementById(currentProjectId).previousSibling;
		console.log(previousSibling);
		let i = 0;
		if(sibling !==null){
			while (sibling) {
				console.log(i + '. ' + sibling.id);
				//update the style;
				sibling.setAttribute('style', 'display:none');
				sibling = sibling.nextSibling;
				i++;
			}
		}

		if(previousSibling !== null){
			while (previousSibling) {
				console.log(i + '. ' + previousSibling.id);
				//update the style;
				previousSibling.setAttribute('style', 'display:none');
				previousSibling = previousSibling.previousSibling;
				i++;
			}
		}

		document.getElementById(currentProjectId).className = 'activeProject';

	}


	resetLayout(e){
		e.stopPropagation();

		console.log('in the resetLayout function');
		console.log(e.currentTarget.id);
		let currentProject = ((document.getElementById(e.currentTarget.id)
			.parentElement)
			.parentElement)
			.parentElement;
		console.log(currentProject);
		let sibling = document.getElementById(currentProject.id).nextSibling;
		let previousSibling = document.getElementById(currentProject.id).previousSibling;
		console.log(sibling);
		console.log( previousSibling);
		let i = 0;
		while (sibling) {
			console.log(i + '. ' + sibling.id);
			//update the style;
			sibling.setAttribute('style', 'display:flex');
			sibling = sibling.nextSibling;
			i++;
		}
		while (previousSibling) {
			console.log(i + '. ' + previousSibling.id);
			//update the style;
			previousSibling.setAttribute('style', 'display:flex');
			previousSibling = previousSibling.previousSibling;
			i++;
		}
		document.getElementById(currentProject.id).className = 'singleProject';
	}
	render(){
		return <div className='websiteProjects' >
			<WebsiteHeader changeLayout={this.props.changeLayout} layout={this.props.props.layout}/>
			<section className='ProjectsHeader'>
				<h1> My Projects </h1>
			</section>
			<section className='websiteProjectsList'>
				<div className='projects'>
					{ProjectsData.map((project,index) =>{
						return <Project info={project} index={index}  onClick={this.updateLayout} resetLayout={this.resetLayout}/>;
					})}
				</div>
			</section>
		</div>;
	}

}

export default Projects;
